.form .field
    min-width: 88px
    position: relative
    flex-grow: 1
    margin-right: 18px
    &:last-child
        margin-right: 0
    .label
        text-transform: uppercase
        line-height: 16px
        font-size: 11px
        color: $color-text-light
        height: 24px
        margin-bottom: -13px
        margin-left: 24px
        border-radius: 10px
        z-index: 1
        position: relative
        display: block
        font-weight: 600
        transition: color .1s ease
        span
            text-overflow: ellipsis
            white-space: nowrap
            max-width: 85%
            overflow: hidden
            cursor: pointer
            padding: 4px
            background: #ffffff
            display: inline-block
    &.error
        & > label > .label
            color: $color-red
    &.focus
        & > label > .input
            border-color: $color-primary
        & > label > .label
            color: $color-primary
            span, p
                color: $color-primary
        .icon-arrow-expand
            filter: none
    &.disabled
        .input
            background: #f5f9fA
            input
                color: $color-text
        .label
            span
                cursor: default
                background: linear-gradient(0, rgba(245,248,250,0) 25%, #fff 50%, rgba(255,255,255,0) 57%)
                border-radius: 28px

    &.no-input
        label
            cursor: pointer
            .inner
                white-space: nowrap
                text-overflow: ellipsis
                overflow: hidden
                .placeholder
                    color: $color-text-light

    input, .button
        width: 100%
        touch-action: none

    &.size-medium
        max-width: 273px

    &.agency-selector
        @include desktop
            .select.dropdown
                width: 450px
        .tag
            margin: 0 0 0 8px
            padding: 0 4px
            &.tag-surrogate
                background: #91D5FF22
                color: #1890FF
                border-color: #1890FF
            &.tag-active
                background: #B7EB8F22
                color: #52C41A
                border-color: #52C41A
        .agency-id
            margin: 0 0 0 10px
            color: #999
        .text-holder
            text-overflow: ellipsis
            max-width: 250px
            overflow: hidden
            white-space: nowrap
            display: inline-block

    &.size-half
        width: 50%

    .error-holder
        color: $color-red
        margin: 5px 0 0 17px

    &.select
        & input
            position: absolute !important
            top: 16px
            opacity: 0
        &, & label, & input
            cursor: pointer

    .value-object
        width: 100%
        height: 100%
        overflow: hidden
        &.placeholder
            color: $color-text-light
            overflow: hidden
            white-space: nowrap

    .input
        border-radius: 40px
        color: $color-text
        background: $color-white
        border: 1px solid $color-gray-border
        line-height: 44px
        padding: 0 27px
        position: relative
        display: flex
        font-size: 14px
        overflow: hidden
        em
            font-style: normal
            color: $color-text-light
        .inner
            position: relative
            display: inline-block
            flex-grow: 2
        .suggestion
            position: absolute
            white-space: nowrap
            left: 0
            top: 0
            z-index: 1
            font-size: 14px
            color: $color-text-light
            &.solid
                color: $color-text
            span
                color: #ffffff

        input[type="text"], input[type="password"], textarea
            border: 0
            width: 100%
            font-size: 14px
            background: transparent
            position: relative
            z-index: 3
            touch-action: none
            &::placeholder
                color: $color-text-light
                opacity: 1

        &.textarea
            height: auto
            min-height: 80px
            border-radius: 16px
            .inner
                padding-top: 14px
                padding-bottom: 6px
                overflow: hidden
                textarea
                    width: 100% !important
                    resize: none
                    overflow: hidden
                    min-height: 40px
                    height: 60px
                    max-height: 200px
                    display: block
                    line-height: 20px
            .placeholder
                color: $color-text-light

        .clear
            background: url("./imgs/cross-small.svg") no-repeat
            width: 8px
            height: 8px
            margin-left: 14px
            display: inline-block
            cursor: pointer

        .icon-wrap
            margin-right: 8px

            .icon
                background-position: 50% 0
        .after-icon-wrap
            padding-left: 14px
            cursor: pointer

.never-submitted
    .possible-hide
        display: none

span.required
    &:after
        content: ' *'
        color: red
