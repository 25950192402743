.tiles.block
    background: #ffffff
    padding-bottom: 100px

    .tile-row
        display: flex
        margin-bottom: 20px
        &.x-2
            .item .body
                height: 300px
        &.x-3
            .item
                flex-basis: 35%
                .body
                    height: 300px
                    .bottom
                        height: 165px
        &.x-4
            .item
                flex-basis: 25%
                .body
                    height: 220px
                    &.offer
                        margin-bottom: 63px
            .info
                padding: 14px 14px 24px
                .title
                    font-size: 20px
                    line-height: 26px
                    font-weight: 500
                    min-height: 34px
        .item
            margin-right: 20px
            flex-grow: 1
            position: relative
            &:last-child
                margin-right: 0
            .body
                background: #f5f5f5
                border-radius: 20px
                background-size: cover
                background-position: top center
                cursor: pointer
                min-height: 50px
                overflow: hidden
            .info
                cursor: pointer
                display: flex
                padding: 10px 10px 18px
            .title
                margin-top: 8px
                font-size: 20px
                line-height: 26px
                font-weight: 500
            .count
                font-size: 16px
                line-height: 27px
                font-weight: 400
    h2
        margin: 76px 0 30px
        font-weight: 500

    .circle-arrow
        margin: 16px 16px auto auto
        width: 40px
        height: 40px

        .icon-arrow
            transform: rotate(-135deg)


@include tablet
    .tiles.block
        padding-left: 20px
        padding-right: 20px
        padding-bottom: 0
        margin-bottom: 100px
        .tile-row
            margin-bottom: 0
            .item
                margin-right: 0
                margin-bottom: 20px
            &.x-2
                flex-direction: column
            &.x-3
                flex-direction: column
                .body
                    height: 300px
            &.x-4
                flex-wrap: wrap
                gap: 20px
                .item
                    flex-basis: 45%
