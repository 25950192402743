.rdp {
  --rdp-cell-size: 40px;
  --rdp-accent-color: #00f;
  --rdp-background-color: #e7edff;
  --rdp-accent-color-dark: #3003e1;
  --rdp-background-color-dark: #180270;
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
  margin: 1em;
}

.rdp-vhidden {
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.rdp-button_reset {
  -webkit-appearance: none;
  appearance: none;
  cursor: default;
  color: inherit;
  font: inherit;
  background: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.rdp-button_reset:focus-visible {
  outline: none;
}

.rdp-button {
  border: 2px solid #0000;
}

.rdp-button[disabled]:not(.rdp-day_selected) {
  opacity: .25;
}

.rdp-button:not([disabled]) {
  cursor: pointer;
}

.rdp-button:focus-visible:not([disabled]) {
  color: inherit;
  background-color: var(--rdp-background-color);
  border: var(--rdp-outline);
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: var(--rdp-background-color);
}

.rdp-months {
  display: flex;
}

.rdp-month {
  margin: 0 1em;
}

.rdp-month:first-child {
  margin-left: 0;
}

.rdp-month:last-child {
  margin-right: 0;
}

.rdp-table {
  max-width: calc(var(--rdp-cell-size) * 7);
  border-collapse: collapse;
  margin: 0;
}

.rdp-with_weeknumber .rdp-table {
  max-width: calc(var(--rdp-cell-size) * 8);
  border-collapse: collapse;
}

.rdp-caption {
  text-align: left;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  display: flex;
}

.rdp-multiple_months .rdp-caption {
  text-align: center;
  display: block;
  position: relative;
}

.rdp-caption_dropdowns {
  display: inline-flex;
  position: relative;
}

.rdp-caption_label {
  z-index: 1;
  white-space: nowrap;
  color: currentColor;
  border: 2px solid #0000;
  align-items: center;
  margin: 0;
  padding: 0 .25em;
  font-family: inherit;
  font-size: 140%;
  font-weight: bold;
  display: inline-flex;
  position: relative;
}

.rdp-nav {
  white-space: nowrap;
}

.rdp-multiple_months .rdp-caption_start .rdp-nav {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.rdp-multiple_months .rdp-caption_end .rdp-nav {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.rdp-nav_button {
  width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  padding: .25em;
  display: inline-flex;
}

.rdp-dropdown_year, .rdp-dropdown_month {
  align-items: center;
  display: inline-flex;
  position: relative;
}

.rdp-dropdown {
  -webkit-appearance: none;
  appearance: none;
  z-index: 2;
  cursor: inherit;
  opacity: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: #0000;
  border: none;
  width: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.rdp-dropdown[disabled] {
  opacity: unset;
  color: unset;
}

.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
  background-color: var(--rdp-background-color);
  border: var(--rdp-outline);
  border-radius: 6px;
}

.rdp-dropdown_icon {
  margin: 0 0 0 5px;
}

.rdp-head {
  border: 0;
}

.rdp-head_row, .rdp-row {
  height: 100%;
}

.rdp-head_cell {
  vertical-align: middle;
  text-transform: uppercase;
  text-align: center;
  height: var(--rdp-cell-size);
  height: 100%;
  padding: 0;
  font-size: .75em;
  font-weight: 700;
}

.rdp-tbody {
  border: 0;
}

.rdp-tfoot {
  margin: .5em;
}

.rdp-cell {
  width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  text-align: center;
  height: 100%;
  padding: 0;
}

.rdp-weeknumber {
  font-size: .75em;
}

.rdp-weeknumber, .rdp-day {
  box-sizing: border-box;
  width: var(--rdp-cell-size);
  max-width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  border: 2px solid #0000;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
  overflow: hidden;
}

.rdp-day_today:not(.rdp-day_outside) {
  font-weight: bold;
}

.rdp-day_selected, .rdp-day_selected:focus-visible, .rdp-day_selected:hover {
  color: #fff;
  opacity: 1;
  background-color: var(--rdp-accent-color);
}

.rdp-day_outside {
  opacity: .5;
}

.rdp-day_selected:focus-visible {
  outline: var(--rdp-outline);
  outline-offset: 2px;
  z-index: 1;
}

.rdp:not([dir="rtl"]) .rdp-day_range_start:not(.rdp-day_range_end) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rdp:not([dir="rtl"]) .rdp-day_range_end:not(.rdp-day_range_start), .rdp[dir="rtl"] .rdp-day_range_start:not(.rdp-day_range_end) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rdp[dir="rtl"] .rdp-day_range_end:not(.rdp-day_range_start) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rdp-day_range_end.rdp-day_range_start {
  border-radius: 100%;
}

.rdp-day_range_middle {
  border-radius: 0;
}

.fp {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  display: inline-block;
  position: relative;
}

.fp:before {
  content: " ";
}

.fp {
  width: 1.33333em;
  line-height: 1em;
}

.ad {
  background-image: url("ad.1a3d4ba7.svg");
}

.ae {
  background-image: url("ae.ef3a2c81.svg");
}

.af {
  background-image: url("af.c95fae16.svg");
}

.ag {
  background-image: url("ag.18112714.svg");
}

.ai {
  background-image: url("ai.087c8a30.svg");
}

.al {
  background-image: url("al.7620f935.svg");
}

.am {
  background-image: url("am.dbdec32e.svg");
}

.ao {
  background-image: url("ao.227b7890.svg");
}

.ar {
  background-image: url("ar.8fa46d18.svg");
}

.as {
  background-image: url("as.8802580d.svg");
}

.at {
  background-image: url("at.0f5be7b1.svg");
}

.au {
  background-image: url("au.227b8038.svg");
}

.aw {
  background-image: url("aw.7cecd8fa.svg");
}

.ax {
  background-image: url("ax.4cc7ba1b.svg");
}

.az {
  background-image: url("az.ecc9bab3.svg");
}

.ba {
  background-image: url("ba.9d668306.svg");
}

.bb {
  background-image: url("bb.eeca81ad.svg");
}

.bd {
  background-image: url("bd.f9ee54f3.svg");
}

.be {
  background-image: url("be.37aceb1b.svg");
}

.bf {
  background-image: url("bf.cd2fdfe8.svg");
}

.bg {
  background-image: url("bg.d75d9b1b.svg");
}

.bh {
  background-image: url("bh.58ff9839.svg");
}

.bi {
  background-image: url("bi.169285bc.svg");
}

.bj {
  background-image: url("bj.27e9ee9f.svg");
}

.bl {
  background-image: url("bl.a6c95af9.svg");
}

.bm {
  background-image: url("bm.8a2d5a97.svg");
}

.bn {
  background-image: url("bn.975a9a86.svg");
}

.bo {
  background-image: url("bo.2e50a38d.svg");
}

.bq {
  background-image: url("bq.7a7af487.svg");
}

.br {
  background-image: url("br.29a33752.svg");
}

.bs {
  background-image: url("bs.63da5964.svg");
}

.bt {
  background-image: url("bt.697ce000.svg");
}

.bv {
  background-image: url("bv.bb834ce3.svg");
}

.bw {
  background-image: url("bw.ad8ffb98.svg");
}

.by {
  background-image: url("by.11724738.svg");
}

.bz {
  background-image: url("bz.a75c5083.svg");
}

.ca {
  background-image: url("ca.b548ad44.svg");
}

.cc {
  background-image: url("cc.8754bece.svg");
}

.cd {
  background-image: url("cd.9883b009.svg");
}

.cf {
  background-image: url("cf.9e6c58ea.svg");
}

.cg {
  background-image: url("cg.87006151.svg");
}

.ch {
  background-image: url("ch.950ac8fe.svg");
}

.ci {
  background-image: url("ci.32d3d0c1.svg");
}

.ck {
  background-image: url("ck.d09ac5d2.svg");
}

.cl {
  background-image: url("cl.23580a15.svg");
}

.cm {
  background-image: url("cm.ab211988.svg");
}

.cn {
  background-image: url("cn.29d1b7f5.svg");
}

.co {
  background-image: url("co.f3f8f216.svg");
}

.cr {
  background-image: url("cr.ad13be7a.svg");
}

.cu {
  background-image: url("cu.1566896e.svg");
}

.cv {
  background-image: url("cv.1227a64b.svg");
}

.cw {
  background-image: url("cw.a6148101.svg");
}

.cx {
  background-image: url("cx.6fc05fc1.svg");
}

.cy {
  background-image: url("cy.a2e34184.svg");
}

.cz {
  background-image: url("cz.7a5c9396.svg");
}

.de {
  background-image: url("de.bd8ce820.svg");
}

.dj {
  background-image: url("dj.f1786e0a.svg");
}

.dk {
  background-image: url("dk.fa91b458.svg");
}

.dm {
  background-image: url("dm.de7e0970.svg");
}

.do {
  background-image: url("do.81558259.svg");
}

.dz {
  background-image: url("dz.00b4997a.svg");
}

.ec {
  background-image: url("ec.da9cc225.svg");
}

.ee {
  background-image: url("ee.4c67e97c.svg");
}

.eg {
  background-image: url("eg.1d12d60c.svg");
}

.eh {
  background-image: url("eh.44ee6238.svg");
}

.er {
  background-image: url("er.9d8b73b7.svg");
}

.es {
  background-image: url("es.fee2b7b4.svg");
}

.et {
  background-image: url("et.391ffdc7.svg");
}

.eu {
  background-image: url("eu.78f57f95.svg");
}

.fi {
  background-image: url("fi.d79cb651.svg");
}

.fj {
  background-image: url("fj.0b8c32a5.svg");
}

.fk {
  background-image: url("fk.5cadb7b8.svg");
}

.fm {
  background-image: url("fm.c69d2bb5.svg");
}

.fo {
  background-image: url("fo.abc968c4.svg");
}

.fr {
  background-image: url("fr.fc48cf9f.svg");
}

.ga {
  background-image: url("ga.ab7b2cb0.svg");
}

.en {
  background-image: url("en.4f169cfe.svg");
}

.gb {
  background-image: url("gb.a4bac8ab.svg");
}

.gd {
  background-image: url("gd.a77ba3b7.svg");
}

.ge {
  background-image: url("ge.11dd993a.svg");
}

.gf {
  background-image: url("gf.a4d85438.svg");
}

.gg {
  background-image: url("gg.79b04dbd.svg");
}

.gh {
  background-image: url("gh.2102ba18.svg");
}

.gi {
  background-image: url("gi.402e7f92.svg");
}

.gl {
  background-image: url("gl.31766599.svg");
}

.gm {
  background-image: url("gm.d7ddbd5a.svg");
}

.gn {
  background-image: url("gn.d70be5b7.svg");
}

.gp {
  background-image: url("gp.e1eedbf9.svg");
}

.gq {
  background-image: url("gq.8222943f.svg");
}

.gr {
  background-image: url("gr.2e461728.svg");
}

.gs {
  background-image: url("gs.d413e10c.svg");
}

.gt {
  background-image: url("gt.87e8b6b1.svg");
}

.gu {
  background-image: url("gu.65ab101e.svg");
}

.gw {
  background-image: url("gw.924d3e3e.svg");
}

.gy {
  background-image: url("gy.0b0ed8eb.svg");
}

.hk {
  background-image: url("hk.14d43390.svg");
}

.hm {
  background-image: url("hm.a4d1cf0b.svg");
}

.hn {
  background-image: url("hn.ad227c2c.svg");
}

.hr {
  background-image: url("hr.bf9a3c77.svg");
}

.ht {
  background-image: url("ht.1f5b0b88.svg");
}

.hu {
  background-image: url("hu.8f711eb9.svg");
}

.id {
  background-image: url("id.c97e7051.svg");
}

.ie {
  background-image: url("ie.52141526.svg");
}

.il {
  background-image: url("il.93b5483b.svg");
}

.im {
  background-image: url("im.e1d54fb4.svg");
}

.in {
  background-image: url("in.96be66ad.svg");
}

.io {
  background-image: url("io.74d4a166.svg");
}

.iq {
  background-image: url("iq.068317f6.svg");
}

.ir {
  background-image: url("ir.d1f84d51.svg");
}

.is {
  background-image: url("is.1a80914e.svg");
}

.it {
  background-image: url("it.87540659.svg");
}

.je {
  background-image: url("je.8c2e123e.svg");
}

.jm {
  background-image: url("jm.b850ccc6.svg");
}

.jo {
  background-image: url("jo.3cff09e0.svg");
}

.jp {
  background-image: url("jp.7a879a96.svg");
}

.ke {
  background-image: url("ke.037a2699.svg");
}

.kg {
  background-image: url("kg.13cb017c.svg");
}

.kh {
  background-image: url("kh.58770a50.svg");
}

.ki {
  background-image: url("ki.e69af1ba.svg");
}

.km {
  background-image: url("km.1114f6d8.svg");
}

.kn {
  background-image: url("kn.bbad9b7f.svg");
}

.kp {
  background-image: url("kp.cb46b62e.svg");
}

.kr {
  background-image: url("kr.97cef22a.svg");
}

.kw {
  background-image: url("kw.fbf44b22.svg");
}

.ky {
  background-image: url("ky.1617ad08.svg");
}

.kz {
  background-image: url("kz.a4df2641.svg");
}

.la {
  background-image: url("la.72a3d77d.svg");
}

.lb {
  background-image: url("lb.d10656c3.svg");
}

.lc {
  background-image: url("lc.3b2c0710.svg");
}

.li {
  background-image: url("li.c5e83204.svg");
}

.lk {
  background-image: url("lk.c278e804.svg");
}

.lr {
  background-image: url("lr.32b838df.svg");
}

.ls {
  background-image: url("ls.97cb1f7b.svg");
}

.lt {
  background-image: url("lt.ab13234e.svg");
}

.lu {
  background-image: url("lu.1ca1e618.svg");
}

.lv {
  background-image: url("lv.27552aa6.svg");
}

.ly {
  background-image: url("ly.a1f627c6.svg");
}

.ma {
  background-image: url("ma.f68f3e3f.svg");
}

.mc {
  background-image: url("mc.7372c2ca.svg");
}

.md {
  background-image: url("md.786a19d7.svg");
}

.me {
  background-image: url("me.0f7efd70.svg");
}

.mf {
  background-image: url("mf.fc48cf9f.svg");
}

.mg {
  background-image: url("mg.f73e68f7.svg");
}

.mh {
  background-image: url("mh.3d72f3b8.svg");
}

.mk {
  background-image: url("mk.c9454bec.svg");
}

.ml {
  background-image: url("ml.43a43931.svg");
}

.mm {
  background-image: url("mm.deb064ca.svg");
}

.mn {
  background-image: url("mn.74d6595a.svg");
}

.mo {
  background-image: url("mo.7adf9929.svg");
}

.mp {
  background-image: url("mp.04eba9b1.svg");
}

.mq {
  background-image: url("mq.52b0badd.svg");
}

.mr {
  background-image: url("mr.cea6fabf.svg");
}

.ms {
  background-image: url("ms.1d412dac.svg");
}

.mt {
  background-image: url("mt.f0815f1c.svg");
}

.mu {
  background-image: url("mu.8a91d33f.svg");
}

.mv {
  background-image: url("mv.9e46581d.svg");
}

.mw {
  background-image: url("mw.13cb7632.svg");
}

.mx {
  background-image: url("mx.74950882.svg");
}

.my {
  background-image: url("my.cc964901.svg");
}

.mz {
  background-image: url("mz.6c6381b5.svg");
}

.na {
  background-image: url("na.cfbcccc1.svg");
}

.nc {
  background-image: url("nc.e079af98.svg");
}

.ne {
  background-image: url("ne.70e3d262.svg");
}

.nf {
  background-image: url("nf.e5bab043.svg");
}

.ng {
  background-image: url("ng.a6e07f9e.svg");
}

.ni {
  background-image: url("ni.b93b0081.svg");
}

.nl {
  background-image: url("nl.b354043e.svg");
}

.no {
  background-image: url("no.bb834ce3.svg");
}

.np {
  background-image: url("np.fcd2a9e9.svg");
}

.nr {
  background-image: url("nr.8f2a2988.svg");
}

.nu {
  background-image: url("nu.eed9e17e.svg");
}

.nz {
  background-image: url("nz.5a7606cc.svg");
}

.om {
  background-image: url("om.307f2765.svg");
}

.pa {
  background-image: url("pa.d5f6b73f.svg");
}

.pe {
  background-image: url("pe.f551af31.svg");
}

.pf {
  background-image: url("pf.6d41cae3.svg");
}

.pg {
  background-image: url("pg.65b9073c.svg");
}

.ph {
  background-image: url("ph.0f36d266.svg");
}

.pk {
  background-image: url("pk.df8c1f69.svg");
}

.pl {
  background-image: url("pl.958e84ae.svg");
}

.pm {
  background-image: url("pm.d8caf600.svg");
}

.pn {
  background-image: url("pn.8072a0a2.svg");
}

.pr {
  background-image: url("pr.5fdc0bb1.svg");
}

.ps {
  background-image: url("ps.12833587.svg");
}

.pt {
  background-image: url("pt.09019f8a.svg");
}

.pw {
  background-image: url("pw.f723e58b.svg");
}

.py {
  background-image: url("py.b16a0a9c.svg");
}

.qa {
  background-image: url("qa.e400cc60.svg");
}

.re {
  background-image: url("re.fc48cf9f.svg");
}

.ro {
  background-image: url("ro.538d9e4d.svg");
}

.rs {
  background-image: url("rs.5cbab1c8.svg");
}

.ru {
  background-image: url("ru.131b1ee6.svg");
}

.rw {
  background-image: url("rw.5a4aae69.svg");
}

.sa {
  background-image: url("sa.0ecea615.svg");
}

.sb {
  background-image: url("sb.3f5dc0e2.svg");
}

.sc {
  background-image: url("sc.86b0ae66.svg");
}

.sd {
  background-image: url("sd.a76d5200.svg");
}

.se {
  background-image: url("se.d0240560.svg");
}

.sg {
  background-image: url("sg.e2c8d798.svg");
}

.sh {
  background-image: url("sh.1eb047cb.svg");
}

.si {
  background-image: url("si.b6d9d497.svg");
}

.sj {
  background-image: url("sj.bb834ce3.svg");
}

.sk {
  background-image: url("sk.e02c809b.svg");
}

.sl {
  background-image: url("sl.f63c84d6.svg");
}

.sm {
  background-image: url("sm.c2eafe07.svg");
}

.sn {
  background-image: url("sn.aa37fde5.svg");
}

.so {
  background-image: url("so.347afa31.svg");
}

.sr {
  background-image: url("sr.be71edf2.svg");
}

.ss {
  background-image: url("ss.424234e5.svg");
}

.st {
  background-image: url("st.fa6dad41.svg");
}

.sv {
  background-image: url("sv.033cfb4b.svg");
}

.sx {
  background-image: url("sx.dc29dd99.svg");
}

.sy {
  background-image: url("sy.915c73c8.svg");
}

.sz {
  background-image: url("sz.a634c53f.svg");
}

.tc {
  background-image: url("tc.6f550bb7.svg");
}

.td {
  background-image: url("td.40862979.svg");
}

.tf {
  background-image: url("tf.18b82a08.svg");
}

.tg {
  background-image: url("tg.fef8582c.svg");
}

.th {
  background-image: url("th.d0efc735.svg");
}

.tj {
  background-image: url("tj.8dcfb4f0.svg");
}

.tk {
  background-image: url("tk.ddbb68c8.svg");
}

.tl {
  background-image: url("tl.6b59b1ab.svg");
}

.tm {
  background-image: url("tm.91fa4bd6.svg");
}

.tn {
  background-image: url("tn.72954a27.svg");
}

.to {
  background-image: url("to.e7b8cef7.svg");
}

.tr {
  background-image: url("tr.58efa312.svg");
}

.tt {
  background-image: url("tt.16e6a195.svg");
}

.tv {
  background-image: url("tv.a76f58ae.svg");
}

.tw {
  background-image: url("tw.0b948241.svg");
}

.tz {
  background-image: url("tz.7c6c8b46.svg");
}

.ua {
  background-image: url("ua.23c23b9d.svg");
}

.ug {
  background-image: url("ug.6fb68e06.svg");
}

.um {
  background-image: url("um.e775f2ec.svg");
}

.us {
  background-image: url("us.e775f2ec.svg");
}

.uy {
  background-image: url("uy.04f8e7c5.svg");
}

.uz {
  background-image: url("uz.ea2f5cc3.svg");
}

.va {
  background-image: url("va.f59c5032.svg");
}

.vc {
  background-image: url("vc.16a367c7.svg");
}

.ve {
  background-image: url("ve.f35d410c.svg");
}

.vg {
  background-image: url("vg.99c1f04a.svg");
}

.vi {
  background-image: url("vi.d1bae985.svg");
}

.vn {
  background-image: url("vn.c1423a3b.svg");
}

.vu {
  background-image: url("vu.04e6f7eb.svg");
}

.wf {
  background-image: url("wf.fc48cf9f.svg");
}

.ws {
  background-image: url("ws.d52b5cda.svg");
}

.xk {
  background-image: url("xk.5dcb16f3.svg");
}

.ye {
  background-image: url("ye.c026968f.svg");
}

.yt {
  background-image: url("yt.32d475ff.svg");
}

.za {
  background-image: url("za.5f2dba94.svg");
}

.zm {
  background-image: url("zm.3c0f8799.svg");
}

.zw {
  background-image: url("zw.47cd0d53.svg");
}

* {
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
}

menu, nav, li, ul {
  list-style: none;
}

a, a[href] {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

.icon.icon-burger {
  filter: brightness(.22);
  background-image: url("burger-small.2bb1a344.svg");
  width: 14px;
  height: 9px;
}

.icon.icon-arrow-next {
  background-image: url("arrow-next.80052b30.svg");
  width: 16px;
  height: 16px;
}

.icon.icon-filters {
  background-image: url("filters.1ff55435.svg");
  width: 18px;
  height: 18px;
}

.icon.icon-refresh {
  background-image: url("refresh.c47c56a3.svg");
  width: 18px;
  height: 18px;
}

.icon.icon-close {
  background-image: url("close.26417aca.svg");
  width: 18px;
  height: 19px;
}

.icon.icon-up, .icon.icon-down {
  background-image: url("up.d46abbd7.svg");
  width: 18px;
  height: 18px;
}

.icon.icon-down {
  transform: rotate(180deg);
}

.icon.icon-remove {
  background-image: url("remove.5c7591f4.svg");
  width: 16px;
  height: 16px;
}

.icon.icon-arrow-expand {
  filter: grayscale();
  background-image: url("arrow-expand.12884a34.svg");
  width: 18px;
  height: 18px;
}

.icon.icon-big-expand {
  background-image: url("big-expand.7d9b3256.svg");
  background-size: cover;
  width: 34px;
  height: 34px;
}

.icon.icon-big-expand.expanded {
  transform: rotate(180deg);
}

.icon.icon-small-expand {
  background-image: url("big-expand.7d9b3256.svg");
  background-size: cover;
  width: 20px;
  height: 20px;
}

.icon.icon-small-expand.expanded {
  transform: rotate(180deg);
}

.icon.icon-white-lock {
  background-image: url("white-lock.8f131062.svg");
  width: 18px;
  height: 18px;
}

.icon.icon-info {
  background-image: url("info-blue.f55dc9ca.svg");
  width: 12px;
  height: 12px;
}

.icon.icon-logout {
  background-image: url("logout.637af902.svg");
  width: 24px;
  height: 24px;
}

.icon.icon-copy {
  background-image: url("copy.6e9950c4.svg");
  width: 15px;
  height: 15px;
}

.icon.icon-big-mail {
  background-image: url("big-mail.22897656.svg");
  background-size: cover;
  width: 105px;
  height: 115px;
}

.icon.icon-download {
  background-image: url("download.3e24ccf6.svg");
  width: 20px;
  height: 20px;
}

.icon.icon-check {
  background-image: url("check.cf80523f.svg");
  width: 11px;
  height: 7px;
}

.icon.icon-search-location {
  background-image: url("location.e8b33d69.svg");
  width: 14px;
  height: 16px;
}

.icon.icon-search-calendar {
  background-image: url("calendar.4eabddd6.svg");
  width: 14px;
  height: 16px;
}

.icon.icon-search-guests {
  background-image: url("guests.05e9035c.svg");
  width: 14px;
  height: 15px;
}

.icon.icon-search-country {
  background-image: url("country.165ec7c3.svg");
  width: 16px;
  height: 16px;
}

.icon.icon-search-white {
  background-image: url("search.e023dce7.svg");
  width: 14px;
  height: 14px;
}

.icon.icon-search {
  background-image: url("search-input.12e246c7.svg");
  width: 18px;
  height: 18px;
}

.icon.icon-summary-location {
  background-image: url("summary-location.4263006e.svg");
  width: 22px;
  height: 22px;
}

.icon.icon-summary-guests {
  background-image: url("summary-guests.f9d7b0fc.svg");
  width: 22px;
  height: 22px;
}

.icon.icon-summary-calendar {
  background-image: url("summary-calendar.f1c8c65e.svg");
  width: 22px;
  height: 22px;
}

.icon.icon-success {
  background-image: url("success.e77680d1.svg");
  width: 40px;
  height: 40px;
}

.icon.icon-warning {
  background-image: url("warning.d6273947.svg");
  width: 40px;
  height: 40px;
}

.icon.icon-information {
  background-image: url("information.ae25fbf7.svg");
  width: 40px;
  height: 40px;
}

.icon.icon-boy {
  background-image: url("boy.7361bdb8.svg");
  background-size: contain;
  width: 24px;
  height: 24px;
}

.icon.icon-girl {
  background-image: url("girl.953cf62f.svg");
  background-size: contain;
  width: 24px;
  height: 24px;
}

@keyframes Appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes moveDownAndUp {
  0%, 100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(16px);
  }
}

html {
  scroll-behavior: smooth;
}

body {
  color: #09090b;
  letter-spacing: .232143px;
  font-family: -apple-system, BlinkMacSystemFont, Inter, Helvetica Neue, Arial Neue, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.container {
  padding: 0 40px;
}

@media (max-width: 1024px) {
  .container {
    padding: 0 20px;
  }
}

span, p {
  color: #09090b;
}

h1 {
  font-size: 30px;
  line-height: 36px;
}

h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
}

h2 span {
  color: #777;
}

section {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 30px;
}

@media (max-width: 1024px) {
  section {
    padding: 0 20px;
  }
}

@media (max-width: 768px) {
  section {
    padding: 0 10px;
  }
}

.section-wrapper {
  margin: 32px auto;
  padding: 0;
}

.animated-border {
  border-bottom: none;
  position: relative;
}

.animated-border:before {
  content: "";
  background-color: currentColor;
  width: 0;
  height: 1px;
  transition: all .2s .2s;
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.animated-border:after {
  content: "";
  background-color: currentColor;
  width: 100%;
  height: 1px;
  transition: all .2s;
  display: block;
  position: absolute;
  bottom: -2px;
  right: 0;
}

.animated-border:hover:before {
  width: 100%;
}

.animated-border:hover:after {
  width: 0;
}

textarea, pre, input, button {
  letter-spacing: inherit;
  font-family: inherit;
}

.link, a.link, a[href].link {
  cursor: pointer;
  color: #1267fb;
}

.link:hover, a.link:hover, a[href].link:hover {
  color: #074bc5;
}

.link.underlined, a.link.underlined, a[href].link.underlined {
  text-decoration: underline;
}

.dark-link {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  color: #09090b !important;
}

.underlined-text {
  border-bottom: 1px solid #0000;
  transition: border-bottom-color .3s ease-in-out;
}

.underlined-text:hover {
  border-bottom-color: #757582;
}

.hide {
  display: none !important;
}

.dual {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.dual .second {
  text-align: right;
}

.dual.column {
  flex-direction: column;
}

.dual.column .second {
  text-align: left;
}

b, strong {
  font-weight: 500;
}

.block-wrapper {
  min-height: 100vh;
  padding-top: 81px;
}

.block-wrapper header {
  margin-top: -81px;
  position: static;
  box-shadow: none !important;
}

.development-block {
  z-index: 100000;
  position: fixed;
  top: 40px;
  right: 30px;
}

.development-block .button {
  color: #ba55d3;
  border-color: #ba55d3;
  margin-bottom: 20px;
}

.hide-desktop {
  display: none;
}

@media (max-width: 1024px) {
  .with-search .block-wrapper {
    padding-top: 161px;
  }

  .hide-desktop {
    display: block;
  }

  .hide-mobile {
    display: none;
  }
}

.lg-button {
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 22px;
  height: auto;
  padding: 11px 24px;
  font-size: 16px;
  font-weight: 500;
  transition: all .3s ease-in-out;
}

.lg-button.button-primary {
  color: #09090b;
  background-color: #fdca00;
  border: 1px solid #fdca00;
}

.lg-button.button-primary:hover {
  color: #fff;
  background-color: #09090b;
  border-color: #09090b;
}

.lg-button.button-secondary {
  color: #09090b;
  background-color: #0000;
  border: 1px solid #09090b;
}

.lg-button.button-secondary:hover {
  color: #fff;
  background-color: #09090b;
  border-color: #09090b;
}

.lg-button.button-dark {
  color: #fff;
  background-color: #09090b;
  border: 1px solid #09090b;
}

.lg-button.button-dark:hover {
  color: #09090b;
  background-color: #fdca00;
  border: 1px solid #fdca00;
}

.circle-arrow {
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  transition: all .2s;
  display: flex;
}

.move-up-down-arrow {
  margin: auto;
  animation: 3s linear infinite moveDownAndUp;
}

.moved-arrow-wrapper:hover .circle-arrow {
  transform: translate(6px, -6px) !important;
}

.block-paddings {
  padding: 120px 0;
}

@media (max-width: 768px) {
  .block-paddings {
    padding: 80px 0;
  }
}

.print {
  display: none;
}

.print-logo {
  width: 137px;
  height: 74px;
  margin: 0 65px;
  display: block;
}

@media print {
  .block-wrapper {
    padding-top: 0 !important;
  }

  div.information {
    margin-top: 10px !important;
  }

  .no-print, .no-print * {
    display: none !important;
  }

  footer, header {
    display: none;
  }

  .print {
    display: block;
  }
}

.accent-frame {
  background: #f7f8fc;
  border-radius: 8px;
  height: auto;
  min-height: 60px;
  margin: 20px 0 35px;
  padding: 16px 0 14px;
  line-height: 18px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.accent-frame + .accent-frame {
  margin-top: -20px;
}

.accent-frame .before {
  border-right: 1px solid #e5e5e5;
  padding: 9px 25px;
}

.accent-frame .before.Cancelled .icon {
  filter: grayscale();
  opacity: .6;
}

.accent-frame .data {
  flex-grow: 1;
  justify-content: space-between;
  padding: 4px 27px;
  display: flex;
}

.accent-frame .data.only {
  color: #38383a;
  flex-direction: column;
}

.accent-frame .data.only div {
  color: #231f20;
  margin-bottom: 8px;
  font-weight: 500;
  line-height: 17px;
}

.accent-frame .data .first .title, .accent-frame .data .second .title {
  margin-bottom: 8px;
  font-weight: 500;
}

.accent-frame .data .first .additional-reference, .accent-frame .data .second .additional-reference {
  margin-top: 20px;
}

.accent-frame .data .first .form, .accent-frame .data .second .form {
  flex-direction: row;
}

.accent-frame .data .first .form .button, .accent-frame .data .second .form .button {
  margin-left: 10px;
}

.accent-frame .data .first {
  padding-right: 20px;
}

.accent-frame .data .second {
  text-align: right;
  min-width: 120px;
}

@media (max-width: 650px) {
  .accent-frame .data {
    flex-direction: column;
  }

  .accent-frame .data .first div, .accent-frame .data .second div {
    margin-bottom: 2px;
  }

  .accent-frame .data .second {
    text-align: left;
    padding-top: 12px;
  }
}

.accent-frame .after {
  padding: 4px 22px 4px 5px;
}

.accent-frame .after .status-updater {
  vertical-align: middle;
  text-align: center;
  width: 70px;
  font-size: 40px;
  line-height: 30px;
  display: inline-block;
  overflow: hidden;
}

.accent-frame .after .status-updater button {
  border-radius: 6px;
  width: 50px;
  height: 50px;
}

.accent-frame .after .status-updater button .icon {
  filter: grayscale();
}

.accent-frame .after .status-updater button:hover .icon {
  filter: none;
}

.allotment li {
  background: url("bullet.7baf8dbf.svg") 3px 5px no-repeat;
  margin-bottom: 18px;
  padding-left: 24px;
  font-size: 14px;
  line-height: 22px;
}

.allotment li .primary {
  font-weight: 500;
}

.allotment li .primary + .additional {
  margin-top: 5px;
}

.allotment li .additional {
  font-weight: 300;
}

.allotment li .additional .remark {
  color: #777;
  font-size: 90%;
  line-height: 17px;
}

.allotment li.gray {
  filter: grayscale();
}

.allotment li.important {
  background-image: url("bullet-important.086360ab.svg");
  background-position: 0 4px;
}

.allotment li.warning {
  background-image: url("bullet-warning.1af56f8e.svg");
  background-position: 0 4px;
}

.allotment li.warn {
  background-image: url("bullet-warn-info.4af45c83.svg");
  background-position: 0 1px;
}

.allotment li .guests {
  color: #231f20;
  font-size: 15px;
}

.allotment li .guests strong {
  font-weight: 400;
}

.amenities {
  display: flex;
}

.amenities .allotment {
  width: 33%;
  padding-right: 20px;
}

.amenities .allotment li {
  text-transform: capitalize;
  margin-bottom: 8px;
}

.billet {
  border: 1px solid #e5e5e5;
  border-radius: 25px;
  padding: 38px 30px 28px;
  font-size: 14px;
  line-height: 17px;
}

.billet img {
  max-width: 100%;
}

.billet.sticky {
  margin-bottom: 50px;
  position: sticky;
  top: 160px;
}

.billet h2 {
  margin-bottom: 20px;
}

.billet h3, .billet h4 {
  margin-bottom: 25px;
}

.billet h4 {
  margin-top: 40px;
  font-size: 20px;
  line-height: 24px;
}

.billet .line {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.billet .line .icon {
  margin-right: 15px;
  position: relative;
  top: 3px;
}

.billet .button {
  width: 100%;
  margin-top: 15px;
}

.billet .dual {
  margin-bottom: 15px;
}

.billet .dual .first {
  color: #777;
  margin-right: 6px;
  font-weight: 300;
}

.billet .dual .second {
  color: #231f20;
}

.billet .subtitle {
  color: #777;
  margin-bottom: 3px;
}

.billet .title {
  margin-top: 0;
}

.billet .title + .subtitle {
  margin: -15px 0 15px;
}

.billet .tag {
  padding: 4px 8px;
  font-size: 13px;
}

.billet .photo-holder {
  border-radius: 20px;
  margin-top: 25px;
  overflow: hidden;
}

.billet .photo-holder .photo {
  background: #f0f5f9 center / cover no-repeat;
  height: 200px;
}

.billet .checkbox-holder {
  margin-top: 20px;
  font-size: 13px;
}

.billet .checkbox-holder .checkbox:hover {
  color: #231f20;
}

.billet .checkbox-holder a, .billet .checkbox-holder a:hover {
  color: #f9a51a;
  margin-left: 4px;
}

.billet .total-price .second {
  color: #1cbe69;
  font-size: 16px;
  font-weight: 600;
}

.billet .restricted-rate {
  text-align: center;
}

.billet-wrapper {
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

@media (max-width: 1024px) {
  .billet-wrapper {
    flex-direction: column;
  }
}

.billet-wrapper .another {
  flex-grow: 1;
}

@media (max-width: 1024px) {
  .billet-wrapper .another {
    width: 100%;
  }
}

.billet-wrapper .billet {
  width: 100%;
  min-width: 360px;
  max-width: 420px;
  margin-left: 30px;
}

.breadcrumbs {
  color: #777;
  cursor: default;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  display: flex;
  position: relative;
}

.breadcrumbs .links {
  padding-right: 30px;
}

.breadcrumbs a:hover {
  color: #231f20;
}

.breadcrumbs .back-button {
  cursor: pointer;
  text-align: right;
  min-width: 60px;
}

.breadcrumbs .back-button span {
  margin-right: 4px;
}

.breadcrumbs .back-button:hover {
  color: #231f20;
}

@media (max-width: 768px) {
  .breadcrumbs {
    margin-top: 30px;
  }
}

.small-arrow-right, .small-arrow-left {
  border: 1px solid;
  border-top: 0;
  border-left: 0;
  width: 5px;
  height: 5px;
  margin: 0 4px;
  line-height: 0;
  display: inline-block;
  position: relative;
  top: -2px;
  transform: rotate(-45deg);
}

.small-arrow-left {
  margin: 0;
  transform: rotate(135deg);
}

button {
  cursor: pointer;
  font: inherit;
  word-break: normal;
  background: none;
  border: 0;
  outline: 0;
  min-width: 0;
  min-height: 0;
  display: inline-block;
}

.button {
  color: #09090b;
  cursor: pointer;
  background: #fff;
  border: 1px solid #f4f4f7;
  border-radius: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 54px;
  padding: 3px 26px;
  font-size: 14px;
  line-height: 20px;
  transition: all .3s;
  display: inline-flex;
}

.button.dark-button {
  background: #f4f4f7;
}

.button:hover {
  color: #ff9d19;
  background-color: #fff;
  border-color: #ff9d19;
}

.button.main {
  color: #fff;
  background: linear-gradient(90deg, #ffcd0b 0%, #fbac19 100%);
  border: 0;
}

.button.main:hover {
  background: linear-gradient(90deg, #ff9d19 0%, #f9a51b 100%);
}

.button.gray {
  color: #b0b0b0;
}

.button.gray:hover {
  color: #f9a51a;
}

.button.green {
  color: #1cbe69;
  border-color: #1cbe69;
}

.button.green:hover {
  color: #f9a51a;
  border-color: #f9a51a;
}

.button.transparent {
  text-transform: none;
  color: #fff;
  background: none;
  border: 1px solid #fff;
  height: 30px;
  padding: 0 15px;
  font-weight: 400;
  line-height: 30px;
}

.button.transparent:hover {
  color: #ff9d19;
  background: #fff;
}

.button.round {
  min-width: 26px;
  min-height: 26px;
  border-radius: 300px !important;
  padding: 0 !important;
}

.button.round .icon {
  vertical-align: middle;
}

.button.small {
  height: auto;
  padding: 6px 18px;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
}

.button.disabled, .button.disabled:hover, .button.disabled:active {
  pointer-events: none;
  color: #888;
  background: #fafafa;
  border-color: #f0f0f0;
  cursor: default !important;
}

.button.disabled.main, .button.disabled:hover.main, .button.disabled:active.main {
  color: #fff;
  background: #d9d9d9;
}

.file-upload {
  padding: 0 30px;
  font-size: 14px;
}

.file-upload input {
  display: none;
}

.cabinet-header section {
  justify-content: space-between;
  max-width: 886px;
  padding: 75px 10px 25px;
  display: flex;
  position: relative;
}

@media (max-width: 768px) {
  .cabinet-header section {
    padding-top: 105px;
  }
}

.cabinet-header section .agent {
  color: #777;
  min-height: 180px;
  display: flex;
}

.cabinet-header section .agent .photo {
  border-radius: 80px;
  width: 100px;
  height: 100px;
  margin: 0 30px 0 15px;
  position: relative;
  overflow: hidden;
}

.cabinet-header section .agent .photo .no-avatar, .cabinet-header section .agent .photo img {
  width: 100%;
  height: 100%;
}

.cabinet-header section .agent .photo .no-avatar {
  background: url("no-avatar.5b7e2064.svg") center / cover no-repeat;
}

.cabinet-header section .agent .data {
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

.cabinet-header section .agent .data h1 {
  color: #231f20;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}

.cabinet-header section .agent .data .company .name {
  font-size: 15px;
  font-weight: 400;
}

.cabinet-header section .agent .data .status {
  vertical-align: bottom;
  border-radius: 25px;
  width: 14px;
  height: 14px;
  margin: 0 5px 0 12px;
  display: inline-block;
}

.cabinet-header section .agent .data .status.PendingVerification {
  background: #ffffe0;
  border: 3px solid #ff0;
}

.cabinet-header section .agent .data .status.FullAccess {
  background: #1cbe69;
  border: 3px solid #bbecd2;
}

.cabinet-header section .agent .data .status.DeclinedVerification {
  background: red;
  border: 3px solid #f08080;
}

.cabinet-header section .agent .data .status.ReadOnly {
  background: #00f;
  border: 3px solid #87ceeb;
}

.cabinet-header section .agent .data .positive {
  color: #1cbe69;
  font-weight: 500;
}

.cabinet-header section .agent .data .section {
  margin-top: 15px;
}

.cabinet-header section .agent .data .section span {
  color: #231f20;
  font-weight: 600;
}

.cabinet-header section .agent .data .section span.positive {
  color: #1cbe69;
  font-weight: 500;
}

.cabinet-header .logout-wrapper {
  white-space: nowrap;
}

.cabinet-header .logout-wrapper .button {
  height: 44px;
  padding: 0 23px 0 18px;
  font-weight: 400;
  line-height: 40px;
  display: block;
}

.cabinet-header .logout-wrapper .button .icon {
  vertical-align: middle;
  filter: grayscale();
  margin: -3px 6px 0 0;
}

.cabinet-header .logout-wrapper .button:hover .icon {
  filter: none;
}

@media (max-width: 768px) {
  .cabinet-header .logout-wrapper {
    position: absolute;
    top: 20px;
    right: 10px;
  }
}

.cabinet-header .cabinet-main-navigation {
  box-shadow: 0 .5px #b9b9b9;
}

.cabinet-header .cabinet-main-navigation section {
  justify-content: flex-start;
  padding: 0 7px;
  display: flex;
}

.cabinet-header .cabinet-main-navigation a {
  text-align: center;
  color: #777;
  align-items: center;
  margin: 0 10px;
  padding: 19px 14px;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  position: relative;
}

.cabinet-header .cabinet-main-navigation a:hover {
  color: #231f20;
}

.cabinet-header .cabinet-main-navigation a.active {
  cursor: default;
  color: #f9a51a;
}

.cabinet-header .cabinet-main-navigation a.active:after {
  content: " ";
  background: linear-gradient(90deg, #f9a51b 0%, #fece08 100%);
  border-radius: 25px;
  width: 100%;
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.rdp {
  --rdp-cell-size: 36px;
  --rdp-accent-color: #f9a51a;
  --rdp-background-color: #fdfbf9;
  --rdp-accent-color-dark: #f9a51a;
  --rdp-background-color-dark: #110700;
  --rdp-outline: 2px solid #fff;
  --rdp-outline-selected: 3px solid #fff;
  margin: .5em;
}

.rdp-table {
  overflow: hidden;
}

.rdp-day:hover {
  color: #fdca00;
}

.rdp-caption_label {
  margin: 0 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}

.rdp-caption {
  margin-bottom: 20px;
}

.rdp-head_cell {
  color: #777;
  font-weight: 600;
}

.rdp-day_today {
  font-weight: 400 !important;
}

.rdp-day {
  margin: 2px;
}

.rdp-nav_button_previous[disabled] {
  visibility: hidden;
}

.rdp-day_selected.rdp-day_range_start, .rdp-day_selected.rdp-day_range_end {
  z-index: 5;
  background: linear-gradient(270deg, #faab19 0%, #fecd0a 100%);
  position: relative;
  border: 0 !important;
  border-radius: 40px !important;
}

.rdp-day_selected.rdp-day_range_start:before, .rdp-day_selected.rdp-day_range_start:after, .rdp-day_selected.rdp-day_range_end:before, .rdp-day_selected.rdp-day_range_end:after {
  display: none !important;
}

.rdp-day_selected.rdp-day_range_start:hover, .rdp-day_selected.rdp-day_range_end:hover {
  color: #fff;
}

.rdp-day.first-in-range, .rdp-day.last-in-range {
  overflow: visible;
}

.rdp-day.first-in-range:before, .rdp-day.last-in-range:after {
  content: "";
  z-index: 1;
  background: #f8f8f8;
  width: 24px;
  height: 36px;
  margin-top: -18px;
  display: block;
  position: absolute;
  top: 50%;
  left: 32px;
}

.rdp-day.first-in-range:before {
  left: auto;
  right: 32px;
}

.rdp-day_range_middle {
  color: #231f20;
  background: #f8f8f8;
  width: 40px;
  max-width: 40px;
  margin-left: 0;
  margin-right: 0;
}

.rdp-day_range_middle:hover {
  background: #f4f4f4;
}

@media (max-width: 768px) {
  .rdp-month {
    z-index: 1;
    position: relative;
  }

  .rdp-caption_end, .rdp-caption_end .rdp-caption {
    position: static;
  }

  .rdp-caption_end .rdp-caption .rdp-caption_label {
    display: none;
  }

  .rdp-caption_end .rdp-caption .rdp-nav {
    z-index: 3;
    top: 44px;
    right: 38px;
  }
}

.filters h3 {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.filters h3.clickable {
  cursor: pointer;
  justify-content: space-between;
  display: flex;
}

.filters h3.clickable.static {
  cursor: default;
}

.filters h3.clickable .icon {
  width: 24px;
  height: 24px;
  margin: -4px 0;
}

@media (max-width: 1024px) {
  .filters h3.clickable {
    display: none;
  }
}

.filters h4 {
  color: #777;
  margin-top: -6px;
  margin-bottom: 7px;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
}

.filters .checkbox-wrapper {
  margin-bottom: 16px;
}

.filters .price-range {
  margin-bottom: 20px;
}

.filters .district-count {
  color: #777;
  font-size: 11px;
}

.filters .link {
  color: #f9a51a;
}

.filters .input {
  height: 44px;
  border: 1px solid #09090b66 !important;
  line-height: 42px !important;
}

.filters .focus > label > .input {
  border-color: #fdca00 !important;
}

.filters .focus > label > .label, .filters .focus > label > .label span, .filters .focus > label > .label p {
  color: #fdca00;
}

@media (max-width: 1024px) {
  .filters .show-mobile {
    display: block;
  }
}

.filtration {
  flex-wrap: wrap;
  margin-bottom: 3px;
  display: flex;
}

.filtration > .item {
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline-block;
  position: relative;
}

.filtration > .item .button {
  -webkit-user-select: none;
  user-select: none;
  flex-direction: row;
  padding: 0 18px;
}

.filtration > .item .button .icon {
  filter: brightness(.7) grayscale();
}

.filtration > .item .button.after-icon {
  padding-right: 12px;
}

.filtration > .item .button.after-icon .icon {
  margin-left: 6px;
}

.filtration > .item .button.leading-icon .icon {
  margin-right: 8px;
}

.filtration > .item .button:hover .icon {
  filter: none;
}

.filtration > .item .button {
  height: 32px !important;
  line-height: 32px !important;
}

.filtration .dropdown.select {
  min-width: 190px;
}

.flag {
  width: 15px;
  height: 10px;
  font-size: 11px;
  display: inline-block;
  position: relative;
}

.flag span {
  outline: 1px solid #80808038;
}

.flag span.np {
  outline: 0;
}

.flag .flag-icon {
  width: 100%;
  height: 100%;
}

footer {
  color: #777;
  z-index: 3;
  background: #f7f8fc;
  width: 100%;
  min-width: 200px;
  padding-top: 35px;
  position: relative;
}

footer a:hover, footer a[href]:hover {
  color: #231f20;
}

footer .company {
  flex-grow: 1;
}

footer .company .logo-wrapper {
  text-align: left;
  height: 68px;
  position: absolute;
  top: 0;
}

footer .company .logo-wrapper .logo {
  filter: brightness(.65);
  background: url("/images/logo/logo-small-gray.png") 0 0 / cover;
  width: 130px;
  height: 69px;
  display: inline-block;
}

footer .company .logo-wrapper .logo:hover {
  filter: brightness(.75);
}

footer section {
  justify-content: space-between;
  display: flex;
}

footer .column {
  width: 100%;
  max-width: 265px;
}

footer .column h3 {
  text-transform: uppercase;
  color: #231f20;
  margin-bottom: 19px;
  font-size: 12px;
}

footer .column li {
  margin-bottom: 18px;
  font-size: 14px;
  line-height: 18px;
  position: relative;
}

footer .column span {
  color: #231f20;
}

footer .payments {
  margin-top: -60px;
  padding-bottom: 30px;
  line-height: 0;
}

footer .payments img {
  background: #fff;
  height: 40px;
  margin-right: 13px;
}

footer .payments img.interval {
  margin-right: 18px;
}

footer .payments img.interval-big {
  margin-right: 25px;
}

footer .payments img.near {
  margin-right: 5px;
}

footer .payments img.transparent {
  background: none;
}

footer .payments img:last-child {
  margin-right: 0;
}

footer .copyright {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
}

footer .service-info {
  font-size: 10px;
}

footer .service-info span {
  color: #777;
  margin-right: 5px;
}

@media (max-width: 1024px) {
  footer {
    padding-top: 90px;
    padding-left: 20px;
    padding-right: 20px;
  }

  footer .company {
    max-width: 0;
  }

  footer .middle {
    flex-direction: column;
    justify-content: flex-start;
  }

  footer .copyright {
    flex-direction: column-reverse;
    margin: 0 auto;
    display: flex;
  }

  footer .copyright div {
    margin-bottom: 20px;
  }

  footer .company .logo-wrapper {
    left: 50%;
    transform: translateX(-50%);
  }

  footer .payments {
    margin-top: 20px;
    padding-bottom: 10px;
  }
}

@media (max-width: 768px) {
  footer {
    margin-top: 30vh;
  }

  footer section {
    flex-direction: column;
  }

  footer .column {
    margin-bottom: 30px;
  }

  footer .column:nth-child(2) {
    order: 1;
  }

  footer ul {
    flex-wrap: wrap;
    justify-content: center;
  }

  footer ul li:last-child {
    margin-right: 0 !important;
  }

  footer .copyright {
    max-width: 300px;
  }

  footer .payments {
    text-align: center;
  }

  footer .contact {
    width: 280px;
    margin: 40px auto 0;
  }
}

.gallery {
  justify-content: space-between;
  width: 100%;
  height: 414px;
  display: flex;
  position: relative;
}

.gallery .big {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  width: 49.5%;
  height: 400px;
  margin-top: 5px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 768px) {
  .gallery .big {
    display: none;
  }
}

.gallery .thumbs {
  width: 49.5%;
  height: 400px;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (max-width: 768px) {
  .gallery .thumbs {
    width: 100%;
  }
}

.gallery .thumbs.scroll {
  flex-flow: column wrap;
  height: 423px;
  overflow-x: scroll;
}

.gallery .thumbs.scroll::-webkit-scrollbar {
  height: 5px;
  line-height: 0;
}

.gallery .thumbs.scroll::-webkit-scrollbar-track {
  background: none;
}

.gallery .thumbs.scroll::-webkit-scrollbar-thumb {
  background-color: #f9a51a;
}

.gallery .thumbs .item {
  cursor: pointer;
  min-width: 49%;
  max-width: 49%;
  height: 195px;
  margin: 5px 2% 5px 0;
  display: inline-block;
  position: relative;
}

.gallery .thumbs .item:last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .gallery .thumbs .item:first-child {
    order: 1;
  }
}

.gallery .sizer {
  background: #f0f5f9 center / cover no-repeat;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

header {
  z-index: 1001;
  background: #fff;
  width: 100%;
  height: 80px;
  font-size: 14px;
  line-height: 22px;
  transition: box-shadow .2s;
  position: fixed;
  box-shadow: 0 .5px #fff;
}

header.fixed {
  transition: box-shadow 1.2s, height .4s;
  box-shadow: 0 .5px #b9b9b9;
}

@media (max-width: 768px) {
  header.fixed {
    overflow: hidden;
    height: 80px !important;
  }
}

header a {
  vertical-align: top;
  display: inline-block;
}

header section {
  justify-content: space-between;
  margin: 0 auto;
  display: flex;
}

header .search-wrapper {
  margin-top: 16px;
  position: relative;
}

header .agent-menu {
  text-align: right;
  white-space: nowrap;
  padding: 17px 0;
}

@media (min-width: 1120px) {
  header .agent-menu {
    min-width: 240px;
  }
}

header .agent-menu .button {
  height: 46px;
  margin-left: 15px;
}

header .agent-menu .button:hover {
  box-shadow: none;
  border-color: #f9a51a;
}

header .agent-link {
  flex-direction: row;
  height: 46px;
  margin-left: 14px;
  padding: 5px;
  display: inline-flex;
}

header .agent-link .icon {
  margin: 0 9px;
}

header .agent-link:hover .icon {
  filter: none;
}

header .agent-link .avatar {
  background: url("no-avatar.5b7e2064.svg") center / contain no-repeat;
  margin: 0;
}

header .agent-link .avatar, header .agent-link .avatar img, header .agent-link .avatar svg {
  width: 34px;
  height: 34px;
  display: inline-block;
}

header .logo-wrapper {
  justify-content: flex-end;
  width: 50%;
  min-width: 157px;
  display: flex;
  transform: translateX(68px);
}

header .logo-wrapper .logo {
  width: 137px;
  height: 74px;
  position: relative;
}

header .logo-wrapper .logo .image, header .logo-wrapper .logo .underline {
  z-index: 2;
  background: url("/images/logo/logo.png") 0 0 / contain no-repeat;
  width: 137px;
  height: 74px;
  display: block;
  position: relative;
}

header .logo-wrapper .logo .underline {
  z-index: 1;
  background: linear-gradient(90deg, #f9a51b 0%, #fece08 100%);
  border-radius: 100px;
  width: 130px;
  height: 130px;
  position: absolute;
  top: -61px;
  left: 3px;
}

@media (max-width: 1024px) {
  .with-search .notifications {
    z-index: 10000 !important;
  }

  header.with-search {
    height: 160px;
  }

  header.with-search .search-wrapper {
    max-width: 100%;
    padding: 0 5px;
    display: block;
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
  }

  header.with-search .search-wrapper section {
    justify-content: space-around;
  }
}

@media (max-width: 768px) {
  header .logo-wrapper {
    justify-content: flex-start;
    width: 140px;
    transform: none;
  }
}

@media (min-width: 1024px) {
  header.with-search .logo-wrapper, header.landing-header .logo-wrapper {
    justify-content: flex-start;
    width: 240px;
    transform: none;
  }
}

.custom-search-notification {
  z-index: 160;
  background-color: #f7f8fc;
  border: 1px solid #e5e5e5;
  padding: 14px 0;
  position: sticky;
  top: 79px;
}

@media (max-width: 768px) {
  .custom-search-notification {
    padding: 0;
    top: 80px;
  }
}

.custom-search-notification section {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.custom-search-notification div {
  align-items: center;
  display: flex;
}

.custom-search-notification strong {
  font-weight: 600;
}

.custom-search-notification button {
  height: 30px;
}

@media (max-width: 768px) {
  .custom-search-notification button {
    height: 26px;
    padding: 2px 8px;
  }
}

.custom-search-notification .icon-information {
  scale: .65;
}

@keyframes Loading {
  0%, 50%, 100% {
    transform: scale(0);
  }

  20% {
    transform: scale(3.5);
  }
}

.loader {
  height: 300px;
  position: relative;
}

.loader.full-page {
  z-index: 999;
  background-color: #f5f5f588;
  height: auto;
  position: fixed;
  inset: 0;
}

.loader.full-page.white {
  background: #fff;
}

.loader.segment {
  position: absolute !important;
}

.loader.segment .wrp {
  top: 350px;
}

.loader .inside {
  text-align: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  display: flex;
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
}

.loader .inside span {
  transform-style: preserve-3d;
  transform-origin: center;
  background-color: #f9a51a;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin: 15px;
  animation: 2.4s ease-in-out infinite Loading;
  display: block;
  position: relative;
  transform: scale(0);
  box-shadow: 0 0 3px #f9a51a55;
}

.loader .inside span:first-of-type {
  animation-delay: 0s;
}

.loader .inside span:nth-of-type(2) {
  animation-delay: .3s;
}

.loader .inside span:nth-of-type(3) {
  animation-delay: .6s;
}

.loader .inside span:nth-of-type(4) {
  animation-delay: .9s;
}

.loader .inside span:nth-of-type(5) {
  animation-delay: 1.2s;
}

.loader .inside span:nth-of-type(6) {
  animation-delay: 1.5s;
}

.loader .inside span:last-of-type {
  animation-delay: 1.8s;
}

.search-big-loader {
  width: 100%;
  height: 100%;
  padding: 40px 90px 0;
}

.search-big-loader h1 {
  text-align: center;
  margin-bottom: 7px;
  font-size: 30px;
  font-weight: 100;
  line-height: 38px;
}

.search-big-loader h2 {
  text-align: center;
  color: #777;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 300;
  line-height: 18px;
}

.search-big-loader canvas {
  width: 100%;
  max-width: 1300px;
  height: 100%;
}

@media (max-width: 1024px) {
  .search-big-loader {
    padding-top: 120px;
  }
}

@media (max-width: 768px) {
  .search-big-loader {
    padding: 100px 20px 200px;
  }

  .search-big-loader h2 {
    margin-bottom: 40px;
  }
}

.modal-wrapper {
  z-index: 5000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.modal-wrapper .hide-close-button .close-button {
  display: none;
}

.modal-scroll {
  text-align: center;
  width: 100%;
  max-height: 100%;
  margin: auto;
  padding: 20px 0 35px;
  overflow: auto;
}

.big-modal-wrapper .modal-scroll {
  overflow: visible;
}

.overlay {
  opacity: .4;
  background: #f9f7f4;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.auth-modal-overlay:after {
  content: "";
  z-index: 50000;
  background: url("/images/logo/logo.png") 0 0 / contain no-repeat;
  width: 137px;
  height: 74px;
  margin-left: -69px;
  display: block;
  position: fixed;
  top: 0;
  left: 50%;
}

.auth-modal-overlay .overlay {
  opacity: 1;
  background: url("/images/account/bg02.svg") 50% 70% / cover no-repeat;
}

.modal {
  text-align: left;
  background: #fff;
  border-radius: 25px;
  width: auto;
  max-width: 480px;
  margin: 0 auto;
  padding: 40px;
  display: inline-block;
  position: relative;
  box-shadow: 0 10px 20px #0003;
}

.modal.wide {
  max-width: 640px;
}

.modal.no-padding {
  padding: 0;
}

@media (max-width: 768px) {
  .modal {
    max-width: 90% !important;
  }
}

.modal h2 {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.modal .title {
  border-bottom: 1px solid #e5e5e5;
  padding: 18px 26px;
}

.modal .content {
  padding: 30px 25px 35px;
}

.modal .danger {
  color: #e25141;
}

.modal h2 {
  margin-bottom: 24px;
}

.modal p {
  margin-bottom: 12px;
}

.modal .button {
  margin-right: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.modal .bottom {
  text-align: center;
  margin-top: 30px;
}

.modal .bottom .button {
  width: 100%;
}

.modal .row + .bottom {
  margin-top: 0;
}

.modal.wide {
  width: 100%;
}

.modal.wide .textarea {
  min-height: 120px !important;
}

.body-wrapper {
  min-width: 480px;
}

body.modal-open {
  overflow: hidden;
}

body.modal-open .body-wrapper > div, body.modal-open header, body.modal-open footer {
  filter: blur(2px);
}

.close-button {
  z-index: 1;
  cursor: pointer;
  padding: 10px 10px 5px 5px;
  position: absolute;
  top: 5px;
  right: 5px;
}

.close-button .icon {
  filter: brightness(0);
  opacity: .4;
  display: block;
}

.close-button:hover .icon {
  filter: none;
  opacity: 1;
}

.auth-modal-background {
  background: #fff url("/images/account/modal-bg.svg") top / 500px no-repeat;
  padding-top: 120px;
}

.cancellation-room-modal {
  max-width: 900px;
  padding: 30px 0;
}

@media (min-width: 1024px) {
  .cancellation-room-modal {
    min-width: 600px;
  }
}

.cancellation-room-modal h2 {
  text-align: center;
}

.cancellation-room-modal strong {
  font-size: 18px;
  font-weight: 600;
}

.cancellation-room-modal strong.cancellation-cost {
  color: #e25141;
}

.cancellation-room-modal strong.final-price {
  color: #1cbe69;
}

.cancellation-room-modal .text {
  text-align: center;
  padding: 0 50px;
  font-size: 14px;
  line-height: 28px;
}

.cancellation-room-modal .table .the-table tr td:last-child {
  padding-right: 10px;
}

.cancellation-room-modal .bottom {
  text-align: center;
}

.cancellation-room-modal .bottom .button {
  width: auto;
}

.accommodation-details-modal {
  width: 85vw;
  max-width: 1300px;
  padding: 0;
}

@media (max-width: 768px) {
  .accommodation-details-modal {
    width: 100vw;
  }
}

.accommodation-details-modal .search-results.block {
  padding: 0;
}

.accommodation-details-modal h2 {
  margin-bottom: 12px;
}

.accommodation-details-modal .gallery {
  margin: 20px 0 !important;
}

@media (max-width: 768px) {
  .accommodation-details-modal .gallery {
    margin-bottom: 40px !important;
  }
}

.accommodation-details-modal .inner {
  padding: 10px 27px 60px;
}

@media (max-width: 768px) {
  .accommodation-details-modal .inner {
    padding: 10px 15px 30px;
  }
}

.navigation {
  z-index: 150;
  background: #f7f8fc;
  min-height: 54px;
  position: relative;
}

@media (min-width: 1024px) {
  .navigation {
    position: sticky;
    top: 80px;
    box-shadow: 0 .5px #b9b9b9;
  }
}

.navigation section {
  flex-wrap: wrap;
  justify-content: flex-start;
  line-height: 24px;
  display: flex;
}

.navigation section a, .navigation section .item {
  cursor: pointer;
  align-items: center;
  margin: 0 5px;
  padding: 15px 11px;
  font-size: 13px;
  display: flex;
  position: relative;
}

.navigation section a:hover, .navigation section .item:hover {
  color: #f9a51a;
}

.navigation section a.active, .navigation section .item.active {
  cursor: default;
  color: #f9a51a;
}

.navigation section a.active:after, .navigation section .item.active:after {
  content: " ";
  background: linear-gradient(#ffcd0b 0%, #fbac19 100%);
  border-radius: 25px;
  width: auto;
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 8px;
  right: 8px;
}

.navigation.linear {
  text-align: center;
}

@media (max-width: 768px) {
  .navigation:not(.linear) section {
    display: block;
  }

  .navigation:not(.linear).minified .item {
    display: none;
  }

  .navigation:not(.linear).minified .item.active {
    background: url("arrow-expand.12884a34.svg") right 20px center no-repeat;
    display: block;
  }
}

.navigation.cabinet-second-navigation {
  background: #fafafa;
  margin-top: 1px;
  box-shadow: 0 .5px #0000001a;
}

.navigation.cabinet-second-navigation section {
  max-width: 886px;
  padding: 0 5px;
}

@media (max-width: 768px) {
  .navigation.cabinet-second-navigation section {
    padding: 0 6px;
  }
}

.navigation.cabinet-second-navigation section a.active {
  color: #231f20;
}

@keyframes NotificationAppear {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

.notifications {
  width: 100%;
  margin-top: 40px;
}

.notifications.fixed {
  z-index: 6000;
  margin-top: 0;
  animation: .4s NotificationAppear;
  position: fixed;
  top: 90px;
}

.notifications.fixed .wrapper {
  max-width: 430px;
  display: block;
  position: sticky;
  top: 20px;
  left: 100%;
}

@media (max-width: 600px) {
  .notifications.fixed .wrapper {
    max-width: 100%;
  }
}

.notifications.fixed .list {
  width: 100%;
  padding: 10px 20px;
  position: absolute;
}

.notifications.fixed .item {
  padding: 14px 0 0;
}

.notifications.fixed .item .content {
  padding-bottom: 14px;
}

.notifications.fixed .item .holder {
  max-height: 150px;
  padding-right: 14px;
  overflow-y: auto;
}

.notifications.fixed .item .text {
  max-width: 240px;
}

.notifications.fixed .with-photo {
  margin-bottom: 0;
}

.notifications.fixed .with-photo .photo img, .notifications.fixed .with-photo img img {
  width: 70px;
  height: 70px;
  margin-left: 20px;
}

@media print {
  .notifications.fixed {
    display: none;
  }
}

.notifications .item {
  background: #fff;
  border-radius: 25px;
  margin-bottom: 20px;
  padding: 14px;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 2px 5px #0003;
}

.notifications .item .content {
  display: flex;
}

.notifications .item .text {
  word-wrap: break-word;
  flex-direction: column;
  justify-content: center;
  min-height: 58px;
  padding-left: 22px;
  line-height: 18px;
  display: flex;
}

.notifications .item .text div {
  margin-bottom: 10px;
}

.notifications .item h2 {
  margin: 0;
  padding: 0 0 7px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.notifications .item .style {
  background-position: center;
  background-repeat: no-repeat;
  width: 90px;
  min-width: 90px;
  box-shadow: .5px 0 #b9b9b9;
}

.notifications .item .close-button {
  top: 23px;
}

.notifications .time {
  color: #777;
  margin-bottom: 10px;
  font-size: 12px;
}

.notifications a[href] {
  color: #1267fb;
}

.notifications .with-photo {
  margin-bottom: -14px;
  display: flex;
}

.notifications .with-photo .text {
  padding-bottom: 14px;
  padding-left: 0;
}

.notifications .with-photo .photo {
  margin-right: 22px;
}

.notifications .with-photo .photo, .notifications .with-photo img {
  max-width: 90px;
  max-height: 90px;
}

.notifications .with-photo .photo img, .notifications .with-photo img img {
  object-fit: cover;
  border-radius: 15px;
  width: 90px;
  height: 90px;
}

.notifications .with-photo .style {
  margin-bottom: 14px;
  margin-right: 22px;
}

.notifications .progress-timer {
  border-radius: 2px;
  width: 100%;
  height: 3px;
  margin-top: -3px;
}

.notifications .bar {
  background: #aaa;
  border-radius: 3px;
  width: 200%;
  height: 100%;
  margin-left: -100%;
  animation: 25s linear progress;
}

.notifications .double .bar {
  animation: 30s linear progress;
}

.notifications .warning .style, .notifications .warning.style {
  background-image: url("warning.d6273947.svg");
}

.notifications .warning .progress-timer .bar {
  background: #f9a51a;
}

.notifications .information .style, .notifications .information.style {
  background-image: url("information.ae25fbf7.svg");
}

.notifications .information .progress-timer .bar {
  background: #3e90f2;
}

.notifications .success .style, .notifications .success.style {
  background-image: url("success.e77680d1.svg");
}

.notifications .success .progress-timer .bar {
  background: #5cc873;
}

@keyframes progress {
  from {
    transform: scaleX(1);
  }

  to {
    transform: scaleX(0);
  }
}

.pager {
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 26px;
  display: flex;
}

@media (max-width: 768px) {
  .pager {
    justify-content: center;
    margin-top: 20px;
  }
}

.pager .button {
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding-left: 2px;
  padding-right: 2px;
}

.pager .button.active {
  color: #f9a51a;
  cursor: default;
  border-color: #f9a51a;
  font-size: 12px;
  font-weight: 700;
}

.pager .button .rotate-left {
  transform: rotate(90deg);
}

.pager .button .rotate-right {
  transform: rotate(-90deg);
}

.pager .points {
  letter-spacing: 1px;
  line-height: 28px;
}

.stars {
  position: relative;
  top: 1px;
}

.stars i {
  vertical-align: baseline;
  background-image: url("star.d90d3e59.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 12px;
  height: 12px;
  display: inline-block;
}

.status.Success, .status.Confirmed, .status.Completed {
  color: #1cbe69;
}

.status.Cancelled, .status.Rejected, .status.Discarded, .status.PendingCancellation, .status.AwaitingCancellation, .status.Error {
  color: #e25141;
}

.table {
  max-width: 100%;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .table {
    padding-bottom: 30px;
  }
}

.table .the-table {
  border-collapse: collapse;
  width: 100%;
  font-size: 13px;
  line-height: 16px;
}

.table .the-table tr {
  border-top: 1px solid #e5e5e5;
}

.table .the-table tr:last-child {
  border-bottom: 1px solid #e5e5e5;
}

.table .the-table tr.clickable {
  cursor: pointer;
}

.table .the-table tr.clickable:hover td {
  background: #f3f4f6;
}

.table .the-table tr td {
  vertical-align: top;
  padding: 35px 30px 35px 0;
}

@media (max-width: 1024px) {
  .table .the-table tr td {
    padding-right: 10px;
  }
}

.table .the-table tr td:first-child {
  padding-left: 40px;
}

.table .the-table tr td:last-child {
  padding-right: 25px;
}

.table .the-table tr .children-row {
  background-color: #f7f8fc;
}

.table .the-table tr .parent-cell {
  align-items: center;
  gap: 32px;
  display: flex;
}

.table .the-table tr .arrow-wrapper {
  background-color: #f7f8fc;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  transition: all .3s;
  display: flex;
}

.table .the-table tr .icon.icon-arrow-next {
  filter: brightness(.5);
  transform: scale(.7);
}

.table .the-table tr .icon-rotated {
  transform: rotate(90deg);
}

.table .the-table strong {
  color: #777;
  margin-bottom: 12px;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  display: block;
}

.table .empty {
  text-align: center;
  background: #fcfcfc;
  border: 1px solid #e5e5e5;
  border-width: 1px 0;
  padding: 120px 0;
  font-size: 17px;
  font-weight: 300;
}

.table .icon-search {
  background-position: 0 3px;
}

.table .controls {
  justify-content: space-between;
  display: flex;
}

.table .controls .form {
  width: 260px;
  margin-right: 16px;
  padding: 30px 0;
}

.table .controls .search-wrap {
  margin-right: 0;
}

.table .controls .left {
  display: flex;
}

@media (max-width: 768px) {
  .table .controls .left {
    flex-direction: column;
  }

  .table .controls .left .form + .form {
    margin-top: -40px;
  }
}

.copier-wrapper {
  flex-direction: row;
  align-items: end;
  display: flex;
}

.copier-wrapper .copier {
  cursor: pointer;
  z-index: 1;
  margin: -6px 0;
  padding: 8px;
  position: relative;
}

.account.block {
  background: #74b1f5 bottom / cover no-repeat fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 160vh;
  margin-top: -81px;
  padding-bottom: 60vh;
  display: flex;
  position: relative;
}

.account.block.white {
  background-color: #0000;
  min-height: 110vh;
  padding-bottom: 10vh;
}

.account.block.white .section > div {
  box-shadow: none;
}

.account.block .small {
  max-width: 550px;
}

.account.block .small .section {
  animation: .33s ease-in-out Appear;
}

.account.block header {
  z-index: 1;
  background: radial-gradient(#ffffffd9, #fff0 70%);
  height: 600px;
  margin-top: 0;
  padding-top: 299px;
  position: absolute;
  top: -300px;
  left: 0;
  right: 0;
  box-shadow: none !important;
}

.account.block header .logo-wrapper {
  justify-content: space-around;
  width: 100%;
  min-width: 0;
  max-width: none;
  transform: none;
}

.account.block section.section {
  margin: 100px 0;
  position: relative;
  z-index: 2 !important;
}

.account.block section.section .link {
  border-bottom: 1px solid;
}

.account.block .section {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  padding: 20px;
  animation: .15s ease-in-out Appear;
  display: flex;
}

.account.block .section > div {
  background: #fff;
  border-radius: 50px;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  min-height: 480px;
  padding: 50px;
  display: flex;
  box-shadow: 0 0 5px 1px #0003;
}

.account.block .label span {
  background: linear-gradient(#fff0 43%, #fff 46% 51%, #fff0 54%) !important;
}

.account.block .button {
  margin-top: 15px;
  font-size: 16px;
}

.account.block .breadcrumbs, .account.block h2, .account.block .paragraph {
  padding-left: 27px;
}

.account.block .breadcrumbs {
  margin: 9px 0 7px;
}

.account.block .form .row:last-child {
  margin-bottom: 0;
}

.account.block h2 {
  line-height: 34px;
}

.account.block h2, .account.block .accent-frame {
  margin-bottom: 12px;
}

.account.block .form {
  margin-top: 30px;
}

.account.block .paragraph {
  color: #777;
  margin: 0 0 15px;
  line-height: 22px;
}

.account.block .paragraph .link {
  color: #231f20;
}

.account.block .paragraph .link:hover {
  color: #f9a51a;
}

.account.block .paragraph.action {
  text-align: right;
  margin: 0 27px 13px 0;
}

.account.block .paragraph.action .link {
  border-bottom-color: #fff;
}

.account.block .paragraph.action .link:hover {
  border-bottom-color: inherit;
}

.account.block .paragraph + .accent-frame {
  margin-top: -10px;
}

.booking.block {
  word-break: break-word;
  padding-bottom: 100px;
}

.booking.block .room-details {
  width: 100%;
  margin: 20px 0 30px;
}

.booking.block .room-details td {
  text-align: left;
  width: 45%;
  padding-bottom: 10px;
  padding-right: 7px;
  font-weight: 400;
}

.booking.block .room-details td:first-child {
  min-width: 140px;
  max-width: 140px;
  width: 140px !important;
}

.booking.block .room-details td:last-child {
  padding-right: 0;
}

.booking.block .row {
  max-width: 540px;
  margin-bottom: 20px;
}

.booking.block .itinerary-wrapper {
  background-color: #f7f8fc;
  border-radius: 20px;
  margin: 32px 0;
  overflow: hidden;
}

.booking.block .itinerary-wrapper .itinerary-number {
  justify-content: space-between;
  align-items: center;
  padding: 2px 36px;
  display: flex;
}

.booking.block .itinerary-wrapper .itinerary-number p {
  padding: 10px 0;
  line-height: 30px;
}

@media (max-width: 1024px) {
  .booking.block .itinerary-wrapper .itinerary-number p {
    line-height: 26px;
  }
}

.booking.block .itinerary-wrapper .itinerary-number button {
  z-index: 160;
  height: auto;
  min-height: 30px;
  position: relative;
}

@media (max-width: 768px) {
  .booking.block .itinerary-wrapper .itinerary-number button {
    min-height: 26px;
    padding: 2px 8px;
  }
}

.booking.block .promotion {
  background-color: #f7f8fc;
  border-radius: 10px;
  flex-direction: column;
  gap: 24px;
  padding: 8px 14px;
  display: flex;
}

.booking.block .promotion b {
  font-weight: 600;
}

.booking.block .promotion p {
  margin-top: 4px;
}

.booking.block .billet {
  min-width: 420px;
}

.booking.block .another .accent-frame:first-child {
  margin-top: 0;
}

.booking.block .room-container {
  margin-top: 40px;
}

.booking.block .room-container .textarea {
  min-height: 100px !important;
}

.booking.block h2 + .allotment {
  margin-top: 35px;
}

@media (max-width: 1024px) {
  .booking.block .billet-wrapper {
    flex-direction: column-reverse;
  }

  .booking.block .billet-wrapper .billet {
    margin: 40px auto 0;
  }
}

.booking.block .tabs-wrapper {
  margin: -12px -20px 1px;
}

@media (max-width: 768px) {
  .booking.block .tabs-wrapper {
    margin: 0;
  }
}

.booking.block .methods-of-payment .line {
  align-items: center;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 34px !important;
}

.booking.block .methods-of-payment .line span {
  margin-left: 4px;
}

.booking.block .methods-of-payment span {
  color: #777;
}

.booking.block .methods-of-payment span.balance {
  color: #1cbe69;
}

.booking.block .methods-of-payment img {
  vertical-align: middle;
  max-width: 25px;
  max-height: 18px;
  margin-left: 12px;
}

.booking.block .methods-of-payment img:last-child {
  margin-left: 15px;
}

.booking.block.external section {
  max-width: 800px;
  margin-top: 80px;
  padding-bottom: 160px;
}

.booking.block.external .short-summary {
  margin: 20px 0 70px;
  font-size: 16px;
  font-weight: 300;
}

.booking.block.external .short-summary div {
  margin-bottom: 10px;
}

.booking.block.external h2 {
  margin-bottom: 20px;
}

.booking.block .cancelled-room {
  cursor: pointer;
  background: #f7f8fc;
  border-radius: 8px;
  padding: 20px;
  position: relative;
}

.booking.block .cancelled-room.expanded {
  cursor: default;
}

.booking.block .cancelled-room .icon-big-expand {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.booking.block .cancelled-room h2 {
  padding-right: 40px;
  font-size: 20px;
}

.booking.block .cancelled-room .status {
  margin: 10px 0 0;
}

.booking.block .cancelled-room .status + .allotment {
  margin-top: 25px;
}

.room-code {
  color: #777;
  margin: 4px 0 16px;
  font-weight: 700;
}

.history-status {
  margin-top: 80px;
}

.history-status h3, .history-status h2 {
  margin-bottom: 20px;
}

.history-status b {
  font-weight: 600;
}

.history-status ol li {
  margin-bottom: 14px;
  margin-left: 17px;
  line-height: 21px;
  list-style: decimal;
}

.offline-booking-cancel {
  text-align: center;
  color: #777;
  margin: 16px auto;
}

.cabinet section {
  max-width: 886px;
  min-height: 500px;
  padding-bottom: 150px;
}

.cabinet h2 {
  color: #231f20;
  margin: 50px 0 35px;
  font-size: 20px;
  line-height: 24px;
}

.cabinet .row {
  margin-bottom: 18px;
}

.cabinet .form .row.controls {
  margin-top: 9px;
  margin-bottom: 0;
  padding: 0 !important;
}

.cabinet .form .row.controls .button {
  min-width: 215px;
}

.cabinet .form .row.controls .field {
  flex-grow: 0;
  width: auto;
}

@media (max-width: 768px) {
  .cabinet .form .row.controls .field {
    width: 100%;
  }
}

.cabinet .form.agent-data .row {
  width: 60%;
}

@media (max-width: 768px) {
  .cabinet .form.agent-data .row {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .cabinet .form.app-settings .field {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .cabinet .form.app-settings .double {
    flex-direction: column;
    margin-bottom: 0;
  }

  .cabinet .form.app-settings .double .field {
    margin-bottom: 20px;
    margin-right: 0;
  }
}

.cabinet .roles-wrapper {
  align-content: flex-start;
  display: flex;
}

@media (max-width: 768px) {
  .cabinet .roles-wrapper {
    flex-direction: column;
  }
}

.cabinet .roles-wrapper .roles {
  flex-flow: column wrap;
  display: flex;
}

.cabinet .roles-wrapper .roles .item {
  margin-bottom: 18px;
  font-weight: 500;
}

.cabinet .roles-wrapper .permissions .title {
  text-transform: uppercase;
  color: #777;
  margin-bottom: 4px;
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  display: block;
}

.cabinet .roles-wrapper .permissions .allotment li {
  font-size: 13px;
}

.cabinet .roles-wrapper .permissions li {
  margin-bottom: 2px;
}

.cabinet .roles-wrapper .permissions ul {
  margin-bottom: 20px;
}

.cabinet .roles-wrapper .permissions .gray {
  color: #e5e5e5;
}

.cabinet h2.in-table + .table {
  margin-top: -110px;
}

.cabinet .search-wrapper {
  max-width: 740px;
  padding: 73px 0 82px;
}

.cabinet .search-wrapper .field:last-child {
  max-width: 330px;
}

.cabinet .breadcrumbs {
  margin: 45px 0 -25px;
}

.search-results.block .text {
  margin-bottom: 34px;
  font-size: 14px;
  line-height: 24px;
}

.search-results.block .text .cut {
  max-height: 200px;
  margin-bottom: 2px;
  position: relative;
  overflow: hidden;
}

.search-results.block .text .cut > div {
  padding-bottom: 10px;
}

.search-results.block .text .cut:before {
  content: " ";
  background: linear-gradient(#fff0, #fff);
  width: 100%;
  height: 23px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.search-results.block .text p, .search-results.block .text b, .search-results.block .text i, .search-results.block .text ul, .search-results.block .text li, .search-results.block .text h1, .search-results.block .text br {
  color: inherit;
}

.search-results.block .text p {
  margin: 2px 0 3px;
}

.search-results.block .text h1 {
  margin: 3px 0 5px;
  font-size: 17px;
}

.search-results.block .text ul {
  padding-left: 16px;
}

.search-results.block .text li {
  margin-top: 2px;
  list-style: disc;
}

.block.document {
  max-width: 60rem;
  padding-top: 80px;
  padding-bottom: 60px;
  font-size: 16px;
  line-height: 22px;
}

.block.document section {
  padding-top: 50px;
}

@media (max-width: 1024px) {
  .block.document section {
    padding-right: 50px;
  }
}

@media (max-width: 768px) {
  .block.document section {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.block.document h1 {
  margin-bottom: 40px;
  font-size: 52px;
  font-weight: 500;
  line-height: 130%;
}

.block.document h2 {
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
}

.block.document h3 {
  margin-top: 35px;
  font-size: 19px;
  font-weight: 400;
}

.block.document p {
  margin: 25px 0;
}

.block.document p + h2 {
  margin-top: 55px;
}

.block.document .indent {
  margin: 15px 0 20px 21px;
}

.block.document ul, .block.document ol {
  margin: 15px 0;
  padding-bottom: 30px;
  padding-left: 21px;
  list-style: disc;
}

.block.document ul ul, .block.document ul ol, .block.document ol ul, .block.document ol ol {
  padding-bottom: 0;
}

.block.document ul li, .block.document ol li {
  margin-bottom: 20px;
  list-style: disc;
}

.block.document ul.dash, .block.document ul.dash > li, .block.document ol.dash, .block.document ol.dash > li {
  list-style: "— ";
}

.block.document ol, .block.document ol > li {
  list-style: upper-latin;
}

.block.document ol.digital, .block.document ol.digital > li, .block.document ol.roman, .block.document ol.roman > li {
  list-style: decimal;
}

.block.document .updated {
  color: #777;
  margin: 45px 0 10px;
  font-style: italic;
}

.block.document .offices {
  padding-right: 20px;
}

.block.document .offices .list {
  flex-wrap: nowrap;
  padding-top: 10px;
  display: flex;
}

.block.document .offices .list div {
  border-left: 1px solid #e5e5e5;
  margin-bottom: 20px;
  margin-left: -1px;
  padding: 20px 50px 20px 20px;
}

.block.document .offices .list div h2 {
  margin-bottom: 15px;
  padding-bottom: 0;
}

.block.document .offices .list div p {
  margin-top: 15px;
  margin-bottom: 0;
}

@media (max-width: 1024px) {
  .block.document .offices .list div {
    padding-right: 20px;
  }
}

@media (max-width: 768px) {
  .block.document .offices .list {
    flex-direction: column;
  }
}

.pdf-button-holder {
  margin: 60px 0 0;
}

.pdf-button-holder .button {
  position: relative;
}

.on-error-page {
  height: 100vh;
  min-height: 600px;
}

.on-error-page header, .on-error-page footer, .on-error-page .cabinet-header, .on-error-page .cabinet-second-navigation {
  display: none;
}

.error-page {
  text-align: center;
}

.error-page.block {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 50px 0 400px;
  display: flex;
}

.error-page .picture {
  background: url("/images/other/service.png") center / contain no-repeat;
  width: auto;
  max-width: 840px;
  height: 500px;
  margin: 0 auto;
  position: relative;
}

.error-page .picture .text {
  color: #fff;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 125px 20px 0 0;
  display: flex;
}

@media (max-width: 768px) {
  .error-page .picture .text {
    padding-top: 80px;
  }
}

.error-page .picture .text h1, .error-page .picture .text h2 {
  font-weight: 700;
}

.error-page .picture .text h1 {
  padding: 0;
  font-size: 64px;
  line-height: 76px;
}

.error-page .picture .text h2 {
  font-size: 13px;
  line-height: 16px;
}

.error-page .button {
  min-width: 180px;
  margin-top: 70px;
}

.error-page.inside {
  margin-top: 115px;
}

.error-page.inside h1 {
  font-size: 30px;
  line-height: 36px;
}

.error-page.inside p {
  color: #38383a;
  margin-top: 20px;
  font-size: 14px;
  line-height: 24px;
}

.error-page.inside .button {
  margin-top: 45px;
}

.denied-page {
  color: #fff;
  background: url("/images/other/denied.svg") top / cover no-repeat;
  margin: 0 -30px;
}

.denied-page header {
  box-shadow: none;
  background: none;
  margin-left: 30px;
}

.denied-page h1 {
  margin-top: 19vh;
  margin-bottom: 50px;
  font-size: 64px;
  font-weight: 700;
  line-height: 76px;
}

.denied-page h3 {
  max-width: 420px;
  margin-bottom: 50px;
  font-size: 15px;
  line-height: 20px;
}

.denied-page .picture {
  text-align: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
}

.management.block {
  padding-bottom: 150px;
}

@media (min-width: 768px) {
  .management.block .sticky-header-before {
    height: 110px;
  }

  .management.block .sticky-header {
    z-index: 1002;
    white-space: nowrap;
    height: 0;
    position: sticky;
    top: 25px;
  }

  .management.block .sticky-header h2 {
    max-width: 35vw;
    overflow: visible;
  }
}

.management.block h2 {
  margin: 25px 0 45px;
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
}

@media (max-width: 768px) {
  .management.block .controls .form {
    flex-direction: column-reverse;
    justify-content: flex-end;
    display: flex;
  }
}

.management.block .content {
  min-height: 370px;
}

.management.block .gray {
  color: #777;
}

.management.block .payment-amount {
  font-size: 90%;
}

.management.block .the-table tr td:first-child:not([colspan]) {
  min-width: 130px !important;
  max-width: 130px !important;
}

.management.block .the-table tr td:nth-child(2):not([colspan]) {
  min-width: 170px !important;
  max-width: 170px !important;
}

.management.block .the-table tr td:nth-child(3):not([colspan]) {
  min-width: 110px !important;
  max-width: 110px !important;
}

.management.block .the-table tr td:nth-child(4):not([colspan]) {
  min-width: 130px !important;
  max-width: 130px !important;
}

.management.block .the-table tr td:nth-child(5):not([colspan]) {
  min-width: 90px !important;
  max-width: 90px !important;
}

.management.block .the-table tr td:nth-child(6):not([colspan]), .management.block .the-table tr td:nth-child(7):not([colspan]) {
  min-width: 80px !important;
  max-width: 80px !important;
}

.management.block .the-table tr td:nth-child(8):not([colspan]) {
  min-width: 110px !important;
  max-width: 110px !important;
}

.management.block .agency .the-table .parent-row td:first-child:not([colspan]) {
  min-width: 220px !important;
  max-width: 220px !important;
}

.management.block .agency .the-table .parent-row td:nth-child(2):not([colspan]), .management.block .agency .the-table .parent-row td:nth-child(3):not([colspan]) {
  min-width: 190px !important;
  max-width: 190px !important;
}

.management.block .agency .the-table .parent-row td:nth-child(4):not([colspan]) {
  min-width: 180px !important;
  max-width: 180px !important;
}

.management.block .agency .the-table .parent-row td:nth-child(5):not([colspan]) {
  min-width: 140px !important;
  max-width: 140px !important;
}

.management.block .agency .the-table .parent-row td:nth-child(6):not([colspan]) {
  min-width: 180px !important;
  max-width: 180px !important;
}

.management.block .agency .the-table .parent-row td:nth-child(7):not([colspan]) {
  min-width: 100px !important;
  max-width: 100px !important;
}

.management.block .agency .the-table tr td:first-child:not([colspan]), .management.block .agency .the-table tr td:nth-child(2):not([colspan]) {
  min-width: 160px !important;
  max-width: 160px !important;
}

.management.block .agency .the-table tr td:nth-child(3):not([colspan]) {
  min-width: 200px !important;
  max-width: 200px !important;
}

.management.block .agency .the-table tr td:nth-child(4):not([colspan]), .management.block .agency .the-table tr td:nth-child(5):not([colspan]) {
  min-width: 100px !important;
  max-width: 100px !important;
}

.management.block .agency .the-table tr td:nth-child(6):not([colspan]) {
  min-width: 110px !important;
  max-width: 110px !important;
}

.management.block .agency .the-table tr td:nth-child(7):not([colspan]), .management.block .agency .the-table tr td:nth-child(8):not([colspan]), .management.block .agency .the-table tr td:nth-child(9):not([colspan]) {
  min-width: 80px !important;
  max-width: 80px !important;
}

.management.block .agency .the-table tr .children-row {
  width: 100%;
}

.management.block .agency .the-table tr .children-row tr {
  border: 0 !important;
}

.management.block .agency .the-table tr .children-row td {
  padding-right: 15px;
}

.management.block .agency .the-table tr .children-row td:first-child {
  padding-left: 20px;
}

.management.block .agency .the-table tr .children-row td:first-child, .management.block .agency .the-table tr .children-row td:nth-child(2) {
  min-width: 200px !important;
  max-width: 200px !important;
}

.management.block .agency .the-table tr .children-row td:nth-child(3) {
  min-width: 160px !important;
  max-width: 160px !important;
}

.management.block .agency .the-table tr .children-row td:nth-child(4) {
  min-width: 120px !important;
  max-width: 120px !important;
}

.management.block .agency .the-table tr .children-row td:nth-child(5) {
  min-width: 95px !important;
  max-width: 95px !important;
}

.management.block .agency .the-table tr .children-row td:nth-child(6) {
  min-width: 85px !important;
  max-width: 85px !important;
}

.management.block .agency .the-table tr .children-row td:nth-child(7), .management.block .agency .the-table tr .children-row td:nth-child(8) {
  min-width: 80px !important;
  max-width: 80px !important;
}

.management.block .agency .agent-filter {
  align-items: flex-start;
  display: flex;
}

.management.block .agency .agent-filter .checkbox-wrapper {
  cursor: pointer;
  margin-top: 52px;
  margin-left: 15px;
}

@media (max-width: 768px) {
  .management.block .agency .agent-filter .checkbox-wrapper {
    margin: 0 0 30px 10px;
  }
}

.management.block.payments-history .table {
  overflow: visible;
}

.management.block.payments-history .table td {
  word-break: break-all;
}

.management.block.payments-history .table .controls .left {
  flex-grow: 1;
  justify-content: end;
  margin-top: 30px;
}

.management.block.payments-history .table .controls .left .form {
  flex-direction: row;
  gap: 17px;
  width: auto;
  min-width: 270px;
  margin-bottom: 20px;
  padding: 0;
}

.management.block.payments-history .table .controls .left .form .button {
  height: 48px;
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 1024px) {
  .management.block.payments-history .table .controls .left .form .wide {
    width: 270px;
  }

  .management.block.payments-history .table .controls .left .form .button {
    margin-top: 11px;
  }
}

@media (max-width: 1024px) {
  .management.block.payments-history .table .controls .left .form {
    flex-direction: column-reverse;
  }
}

.management.block.payments-history .table .controls .left .form .row {
  margin-bottom: 0;
}

.management.block.payments-history .the-table tr td:first-child {
  min-width: 90px !important;
  max-width: 90px !important;
}

.management.block.payments-history .the-table tr td:nth-child(2) {
  min-width: 130px !important;
  max-width: 130px !important;
}

.management.block.payments-history .the-table tr td:nth-child(3) {
  min-width: 100px !important;
  max-width: 100px !important;
}

.management.block.payments-history .the-table tr td:nth-child(4) {
  min-width: 200px !important;
  max-width: 200px !important;
}

.management.block.payments-history .the-table tr td:nth-child(5), .management.block.payments-history .the-table tr td:nth-child(6), .management.block.payments-history .the-table tr td:nth-child(7) {
  min-width: 80px !important;
  max-width: 80px !important;
}

.payment.block {
  padding-bottom: 100px;
}

.payment.block section {
  max-width: 520px;
  padding-top: 100px;
}

.payment.block .icon.icon-info {
  cursor: default;
  margin-right: -12px;
  position: relative;
  top: -2px;
  right: -16px;
}

.payment.block h2 {
  font-weight: 300;
}

.payment.block h2 + form .form {
  margin-top: 20px;
}

.payment.block .checkbox .icon.icon-info {
  right: -7px;
}

.payment.block .accent-frame {
  margin-bottom: 50px;
}

.payment.block .accent-frame .data.only {
  color: #231f20;
}

.payment.block .form .field {
  position: relative;
}

.payment.block .form .field.force-incorrect .input.valid:after {
  display: none;
}

.payment.block .form .field.valid:after {
  content: " ";
  background: url("check-round.b6819360.svg") 0 0 no-repeat;
  width: 18px;
  height: 18px;
  display: block;
  position: absolute;
  top: 4px;
  right: -5px;
}

.payment.block .form .field .after-icon-wrap img {
  height: 20px;
  margin: 12px 0 -6px;
}

.payment.block .error-page .text {
  padding-bottom: 60px;
}

.payment.block .breadcrumbs {
  margin-top: -20px;
}

.payment.block .button {
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 12px;
  font-size: 16px;
  font-weight: 500;
}

.payment.block .button .icon {
  margin-right: 12px;
}

.rooms {
  min-height: 65vh;
}

.rooms .group {
  margin: 12px 0;
}

.rooms .group .name {
  cursor: pointer;
  background: #f7f8fc;
  border-radius: 8px;
  justify-content: space-between;
  padding: 20px 60px 20px 20px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  display: flex;
  position: relative;
}

.rooms .group .name .icon-big-expand {
  cursor: pointer;
  position: absolute;
  top: 14px;
  right: 14px;
}

.rooms .group .name .min-price {
  font-size: 13px;
}

.rooms .name + .room, .rooms .room:first-child {
  border-top: 1px solid #d8dadc;
}

.rooms .room {
  border-bottom: 1px solid #d8dadc;
  margin-top: 12px;
  padding: 16px 20px;
  font-size: 12px;
  line-height: 18px;
}

@media (min-width: 768px) {
  .rooms .room:hover .button {
    background: linear-gradient(90deg, #ff7a00 0%, #f9a51b 100%);
    border-color: #fff;
  }
}

.rooms .room .show-phone, .rooms .room .phone-tags {
  display: none;
}

.rooms .room .room-details {
  cursor: pointer;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.rooms .room .room-details > div {
  flex-direction: column;
  display: flex;
}

@media (max-width: 768px) {
  .rooms .room .room-details {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .rooms .room .room-details .info {
    width: 60%;
    max-width: 60%;
  }

  .rooms .room .room-details .price {
    width: 30%;
    max-width: 30%;
  }

  .rooms .room .room-details .button-holder {
    width: 10%;
    max-width: 10%;
  }

  .rooms .room .room-details .deadline-column, .rooms .room .room-details .meal-plan, .rooms .room .room-details .hide-phone {
    display: none;
  }

  .rooms .room .show-phone {
    display: inline-block;
  }

  .rooms .room .phone-tags {
    padding: 10px 20px 0 0;
    display: block;
  }

  .rooms .room .phone-tags .tag div, .rooms .room .phone-tags .tag span {
    display: inline;
  }
}

.rooms .room .info {
  width: 45%;
  padding-right: 20px;
}

.rooms .room .deadline-column {
  width: 20%;
  padding-right: 20px;
  line-height: 20px;
}

.rooms .room .deadline-column span, .rooms .room .deadline-tag span {
  display: block;
}

.rooms .room .deadline-column .refundable, .rooms .room .deadline-tag .refundable {
  color: #33ae3c;
}

.rooms .room .deadline-column .within-deadline, .rooms .room .deadline-tag .within-deadline {
  color: #797f89;
}

.rooms .room .deadline-column .non-refundable, .rooms .room .deadline-tag .non-refundable {
  color: #eb001b;
}

.rooms .room .meal-plan {
  width: 15%;
  padding-right: 20px;
}

.rooms .room .price {
  width: 15%;
}

.rooms .room .button-holder {
  width: 5%;
}

.rooms .room .info {
  padding-right: 30px;
}

.rooms .room .info h3 {
  color: #016fd0;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  display: flex;
}

.rooms .room .info > div {
  color: #231f20;
  margin-bottom: 4px;
}

.rooms .room .label {
  color: #777;
  margin-bottom: 8px;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  display: block;
}

.rooms .room strong {
  color: #231f20;
}

.rooms .room .meal-plan, .rooms .room .deadline-column {
  font-size: 14px;
}

.rooms .room .price {
  text-align: right;
  width: 150px;
  min-width: 120px;
  padding-right: 10px;
}

.rooms .room .price .daily-rates-button-holder button {
  white-space: nowrap;
  margin-top: 2px;
  margin-right: -2px;
  padding: 0;
}

.rooms .room .price .daily-rates-button-holder button .icon-big-expand {
  width: 17px;
  height: 17px;
  margin-left: 4px;
}

.rooms .room .button-holder {
  justify-content: center;
  align-items: flex-end;
  min-width: 40px;
  padding-bottom: 30px;
}

.rooms .room .button-holder .button {
  width: 34px;
  height: 34px;
  padding: 0;
}

.rooms .tag {
  margin-left: 0;
  margin-right: 4px;
}

.tag {
  color: #231f20;
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  margin: 5px 18px -8px -9px;
  padding: 2px 8px;
  font-size: 11px;
  display: inline-block;
}

.tag.clickable:hover {
  color: #f9a51a;
  border-color: #f9a51a;
}

.tag .icon {
  background-size: contain;
  width: 13px;
  height: 13px;
  margin-right: 6px;
}

.daily-rates-contract-table {
  border: 1px solid #f7f8fc;
  border-radius: 10px;
  margin-top: 5px;
  line-height: 20px;
  overflow: hidden;
}

.daily-rates-contract-table > div:first-child {
  padding: 6px 25px;
}

.daily-rates-contract-table > div {
  align-items: center;
  display: flex;
}

.daily-rates-contract-table > div:nth-child(odd) {
  background: #f7f8fc;
}

.daily-rates-contract-table .left, .daily-rates-contract-table .right {
  flex-grow: 1;
}

@media (max-width: 768px) {
  .daily-rates-contract-table .left span, .daily-rates-contract-table .right span {
    display: block;
  }
}

.daily-rates-contract-table .right {
  text-align: right;
}

@media (max-width: 768px) {
  .daily-rates-contract-table .right {
    width: 100px;
  }
}

.daily-rates-contract-table .room-inner {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.daily-rates-contract-table .room-inner > div {
  align-items: center;
  padding: 14px 25px;
  display: flex;
}

.daily-rates-contract-table .room-inner > div:nth-child(odd) {
  background: #fff;
}

.daily-rates-contract-table .room-inner > div:nth-child(2n) {
  background: #f7f8fc;
}

.daily-rates-contract-table .room-dates {
  display: flex;
}

.daily-rates-contract-table.rates-big .room-inner {
  padding: 5px 0;
}

.daily-rates-contract-table.rates-big .room-name {
  flex-grow: 2;
  max-width: 200px;
}

@media (max-width: 768px) {
  .daily-rates-contract-table.rates-big .room-name {
    max-width: 140px;
  }
}

.daily-rates-contract-table.rates-big .room-dates {
  padding: 4px 0;
}

.daily-rates-contract-table.rates-big .left, .daily-rates-contract-table.rates-big .room-name {
  padding-left: 25px;
}

.daily-rates-contract-table.rates-big .right, .daily-rates-contract-table.rates-big .room-name {
  padding-right: 25px;
}

.daily-rates-contract-table.rates-big > div {
  padding: 0;
}

.search-fullsize-wrapper {
  padding-top: 30px;
}

@media (max-width: 768px) {
  .search-fullsize-wrapper {
    padding-top: 10px;
  }
}

.search .form-block {
  grid-gap: 30px;
  background: #2e6d6d url("/images/search/sea.png") bottom / cover no-repeat;
  border-radius: 26px;
  grid-template-columns: 1fr 1fr;
  padding: 40px 60px;
  display: grid;
}

.search .form-block .checkbox-wrapper span {
  font-size: 14px;
}

.search .form-block .value-object {
  white-space: nowrap;
}

@media (max-width: 1200px) {
  .search .form-block {
    grid-template-columns: 1fr 2fr;
    padding: 40px;
  }
}

@media (max-width: 768px) {
  .search .form-block {
    grid-template-columns: 1fr;
    padding: 26px;
  }
}

.search .form-block .title {
  max-width: 450px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .search .form-block .title {
    grid-row: 2 / span 1;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-top: 0;
    padding: 0 20px 20px;
  }
}

.search .form-block .title h1, .search .form-block .title span {
  color: #fff;
}

.search .form-block .title h1 {
  margin-bottom: 40px;
  font-size: 48px;
}

@media (max-width: 1200px) {
  .search .form-block .title h1 {
    font-size: 40px;
  }
}

@media (max-width: 1024px) {
  .search .form-block .title h1 {
    margin-bottom: 20px;
    font-size: 34px;
  }
}

.search.block .fullsize {
  background-color: #fff;
  border-radius: 24px;
  justify-content: center;
  gap: 20px;
  padding: 40px;
  display: flex;
}

@media (max-width: 1024px) {
  .search.block .fullsize {
    padding: 20px;
  }
}

.search.block .fullsize .field {
  margin-right: 0;
}

.search.block .fullsize > .row {
  grid-template-columns: repeat(2, 1fr);
  gap: 18px;
  margin-bottom: 0;
  display: grid;
}

@media (max-width: 640px) {
  .search.block .fullsize > .row {
    grid-template-columns: 1fr;
  }
}

.search.block .fullsize .button-holder {
  width: 100%;
  margin-top: 10px;
}

.search.block .fullsize .button-holder button {
  color: #fff;
  width: 100%;
  height: 44px;
}

.search.block .fullsize .button-holder button:hover {
  background-color: #f9a61b;
  border-color: #f9a61b;
}

.search.block .fullsize .guests-field > label > .input {
  padding-right: 6px;
}

@media (min-width: 1024px) and (min-height: 750px) {
  .search-results.block.first-step .heading-section {
    z-index: 10;
    background: #fff;
    flex-direction: column;
    padding-right: 0;
    display: flex;
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
  }

  .search-results.block.first-step .head .title h2 div {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 90%;
    margin-right: 8px;
    display: inline-block;
    overflow: hidden;
  }

  .search-results.block.first-step .two-column-wrapper .column {
    height: 100%;
    padding: 4px 25px 4px 1px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .search-results.block.first-step .two-column-wrapper .column.left {
    overscroll-behavior-y: none;
    scrollbar-color: #fff #fff;
    scrollbar-width: thin;
    height: calc(100vh - 224px);
    transition: scrollbar-color .3s;
    position: fixed;
    top: 224px;
  }

  .search-results.block.first-step .two-column-wrapper .column.left:hover {
    scrollbar-color: #f9a51a #fff;
  }

  .search-results.block.first-step .two-column-wrapper .column.right {
    padding-top: 142px;
    padding-left: 266px;
  }

  .search-results.block.first-step.with-itn .notification-wrapper {
    z-index: 3;
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
  }

  .search-results.block.first-step.with-itn .heading-section {
    padding-top: 8px;
    top: 122px;
  }

  .search-results.block.first-step.with-itn .heading-section .upper-part {
    height: 48px;
  }

  .search-results.block.first-step.with-itn .two-column-wrapper .column.left {
    height: calc(100vh - 254px);
    top: 244px;
  }

  .search-results.block.first-step.with-itn .two-column-wrapper .column.right {
    padding-top: 182px;
  }
}

.search-results.block.first-step .request {
  color: #777 !important;
}

.search-results.block {
  animation: .42s ease-in-out Appear;
}

.search-results.block.with-padding {
  padding-bottom: 100px;
}

@media (max-width: 1024px) {
  .search-results.block {
    padding-top: 80px;
  }
}

.search-results.block .notification-wrapper .custom-search-notification {
  padding: 0;
}

.search-results.block .head .request span {
  margin: 0 3px;
}

.search-results.block .head .request span:first-child {
  margin-left: 0;
}

.search-results.block .head .title h1 {
  margin: 3px 0 16px;
  font-size: 24px;
  font-weight: 500;
}

.search-results.block .head .title h2 {
  margin: 5px 0 10px;
}

.search-results.block .head .title h2 div {
  display: inline;
}

.search-results.block .head .title h2 span {
  color: #777;
  vertical-align: top;
  font-size: 18px;
}

.search-results.block .head .title .stars {
  margin: -10px 0 10px;
}

.search-results.block .preferred {
  position: relative;
  background-color: #f1ede7 !important;
}

.search-results.block .preferred .plate {
  color: #fff;
  text-transform: uppercase;
  background-color: #f9a51a;
  border-radius: 4px 4px 4px 0;
  padding: 4px 10px;
  font-weight: 600;
  position: absolute;
  top: 24px;
  left: 6px;
}

.search-results.block .preferred .plate.no-photo {
  border-radius: 4px;
  display: inline-block;
  position: relative;
  top: -3px;
  left: 0;
}

.search-results.block .preferred .plate.no-photo:after {
  content: none;
}

.search-results.block .preferred .plate:after {
  content: "";
  border-top: 6px solid #b9b9b9;
  border-bottom: 6px solid #0000;
  border-left: 6px solid #0000;
  border-right: 5px solid #b9b9b9;
  position: absolute;
  top: 24px;
  left: 0;
}

.search-results.block .contract .summary {
  cursor: pointer;
  background: #f4f4f6;
  border-radius: 12px;
  margin-bottom: 8px;
  padding: 15px 17px;
  display: flex;
}

@media (max-width: 768px) {
  .search-results.block .contract .summary {
    flex-direction: column;
  }
}

.search-results.block .contract .summary .photo {
  cursor: pointer;
  background-color: #fff;
  background-position: center;
  background-size: cover;
  border-radius: 9px;
  flex-basis: 196px;
  justify-content: center;
  width: 210px;
  min-width: 210px;
  height: 130px;
  margin-right: 17px;
  display: flex;
  overflow: hidden;
}

@media (max-width: 768px) {
  .search-results.block .contract .summary .photo {
    flex-basis: 250px;
    width: 100%;
    margin-bottom: 20px;
  }
}

.search-results.block .contract .summary .upper {
  padding-bottom: 15px;
}

.search-results.block .contract .summary .bottom {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.search-results.block .contract .detail {
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  min-height: 110px;
  display: flex;
}

@media (max-width: 768px) {
  .search-results.block .contract .detail {
    min-height: 0;
  }
}

.search-results.block .contract .detail .name h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}

.search-results.block .contract .detail .name h2 span {
  color: #231f20;
  margin-right: 4px;
}

.search-results.block .contract .detail .price {
  color: #777;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.search-results.block .contract .detail .price .text-price {
  color: #231f20;
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
}

.search-results.block .contract .detail .price .text-markup {
  color: #231f20;
  margin-top: 4px;
  font-weight: 400;
}

.search-results.block .contract .detail .stars {
  white-space: nowrap;
  display: inline-block;
  position: relative;
  top: 1px;
}

.search-results.block .contract .detail .text {
  color: #777;
  font-size: 12px;
  line-height: 18px;
}

.search-results.block .contract .detail .address {
  color: #555;
  margin-top: 4px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  display: inline-block;
}

.search-results.block .contract .detail .availability {
  cursor: pointer;
  height: 32px;
  padding: 8px 16px;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}

.search-results.block .contract .detail .availability:hover {
  color: #f9a51a;
}

.search-results.block .rooms {
  margin-bottom: 50px;
}

.search-results.block .rooms .price {
  font-size: 18px;
  line-height: 22px;
}

.search-results.block .rooms .price .note {
  font-size: 12px;
}

.search-results.block .stars {
  display: inline-block;
}

.search-results.block .stars i {
  width: 20px;
  height: 18px;
  margin-right: 2px;
}

.search-results.block .gallery {
  margin: 20px 0 10px;
}

.search-results.block .another {
  padding-right: 40px;
}

@media (max-width: 1024px) {
  .search-results.block .another {
    padding-right: 0;
  }
}

.search-results.block .another h2 {
  margin: 30px 0 22px;
}

.search-results.block .another h2:first-child {
  margin-top: 10px;
}

.search-results.block .finish {
  text-align: center;
  color: #777;
  margin: 60px 0 30px;
  font-size: 13px;
  font-weight: 300;
}

.search-results.block .nothing-found {
  padding: 40px 0 0 20px;
}

.search-results.block .nothing-found.empty-page {
  flex-direction: column;
  justify-content: center;
  gap: 80px;
  padding: 0 0 150px;
  display: flex;
}

.search-results.block .nothing-found.empty-page section {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 1024px) {
  .search-results.block .nothing-found.empty-page {
    margin-left: -265px;
  }
}

.search-results.block .nothing-found .text {
  font-size: 14px;
  line-height: 22px;
}

.search-results.block .nothing-found .text .button {
  margin: 0 0 50px;
}

.search-results.block .nothing-found .text h2 {
  margin-bottom: 30px;
}

.search-results.block .nothing-found .text h3 {
  margin-bottom: 10px;
}

.search-results.block .checkin-time {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
}

.search-results.block .details-short {
  cursor: pointer;
  background: #f6f3eb;
  border-radius: 25px;
  width: auto;
  margin: 15px 0;
  padding: 20px 40px;
  position: relative;
}

@media (max-width: 768px) {
  .search-results.block .details-short {
    margin: 30px 0;
    padding-left: 20px;
    padding-right: 20px;
  }

  .search-results.block .details-short h1 {
    font-size: 24px !important;
    line-height: 30px !important;
  }
}

.search-results.block .details-short .photo {
  float: right;
  padding-left: 20px;
  position: relative;
}

.search-results.block .details-short .photo img {
  border-radius: 4px;
  max-width: 150px;
  max-height: 120px;
}

.search-results.block .details-short .photo .plate {
  top: 8px;
}

.search-results.block .details-short .short-details {
  flex-direction: column;
  gap: 8px;
  margin-top: 25px;
  padding-right: 60px;
  font-size: 14px;
  display: flex;
}

.search-results.block .details-short h1 {
  margin-bottom: 0 !important;
  font-size: 30px !important;
  line-height: 38px !important;
}

.search-results.block .details-short .button {
  position: absolute;
  bottom: 15px;
  right: 40px;
}

@media (max-width: 768px) {
  .search-results.block .details-short .button {
    right: 20px;
  }
}

.search-results.block .details-short .stars {
  margin: 0 10px 3px 0 !important;
}

@media (min-width: 1024px) {
  .search-results.block .two-column-wrapper {
    flex-grow: 1;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .search-results.block .two-column-wrapper .column {
    height: 100%;
    padding: 4px 25px 4px 1px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .search-results.block .two-column-wrapper .column.left {
    min-width: 265px;
    max-width: 265px;
  }

  .search-results.block .two-column-wrapper .column.left .filters {
    margin-top: -22px;
    padding-bottom: 30px;
  }

  .search-results.block .two-column-wrapper .column.right {
    flex-grow: 1;
  }

  .search-results.block .upper-part .button-toggle-filters {
    display: none;
  }

  .search-results.block .upper-part .filtration > .item:first-child {
    margin-right: 0;
  }

  .search-results.block .upper-part .right {
    padding-right: 25px;
  }
}

@media (min-width: 768px) {
  .search-results.block .upper-part {
    justify-content: space-between;
    display: flex;
  }
}

@media (max-width: 1024px) {
  .search-results.block .two-column-wrapper .column.left {
    display: none;
  }
}

.search.block .short {
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 50px;
  flex-direction: row;
  justify-content: center;
  width: auto;
  max-width: 740px;
  transition: border-color .3s ease-in-out;
  animation: .15s ease-in-out Appear;
  position: relative;
}

.search.block .short > .field {
  text-align: center;
  flex-grow: 0;
  min-width: 0;
  margin-right: 0;
  padding: 0 15px;
  position: static;
}

.search.block .short > .field .dropdown {
  text-align: left;
}

.search.block .short > .field:first-child .value-object {
  text-overflow: ellipsis;
}

.search.block .short > .field:nth-child(2) {
  flex-grow: 1;
}

.search.block .short > .field:nth-child(3) {
  min-width: 110px;
}

.search.block .short > .field:nth-child(4) {
  min-width: 46px;
  padding-right: 12px;
}

.search.block .short > .field + .field {
  background: url("separator.fe10112d.svg") 0 no-repeat;
}

.search.block .short > .field > label .input {
  border: 0;
  height: 46px;
  margin-right: 0;
  padding: 0;
  line-height: 44px;
}

.search.block .short > .field > label .input:first-child {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.search.block .short .error:not(.focus) > label .placeholder {
  color: #e25141 !important;
}

.search.block .short .button-holder {
  min-width: 47px;
  max-width: 47px;
  padding: 0;
  background: none !important;
}

.search.block .short .main.button {
  width: 34px;
  height: 34px;
  margin: 6px 8px 0 6px;
  padding: 0;
}

.search.block .short .overall {
  position: relative;
}

.search.block .short .overall:not(.focus) {
  max-width: 240px;
}

.search.block .short .overall.focus {
  z-index: 30;
  background: #fff;
  border: 1px solid #fdca00;
  border-radius: 42px;
  max-width: none;
  padding-left: 15px;
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
}

.search.block .short .flags {
  text-transform: capitalize;
}

.search.block .short .flags .flag {
  margin-right: 4px;
}

.search.block .short .flags .element + .element {
  margin-left: 12px;
}

.search.block .short .date.dropdown {
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 670px) {
  .search.block .short .overall:not(.focus) {
    max-width: 170px;
  }
}

@media (max-width: 680px) {
  .search.block .short .overall:not(.focus) {
    max-width: 170px;
  }
}

@media (max-width: 580px) {
  .search.block .short {
    font-size: 12px !important;
  }

  .search.block .short input, .search.block .short .input, .search.block .short .placeholder, .search.block .short .suggestion {
    touch-action: none;
    font-size: 12px !important;
  }

  .search.block .short > .field:first-child {
    flex-grow: 0;
  }

  .search.block .short > .field:first-child > label .icon-wrap {
    display: block;
  }

  .search.block .short > .field:nth-child(2) {
    flex-grow: 1;
    min-width: 90px;
  }

  .search.block .short > .field:nth-child(3) {
    min-width: 70px;
  }

  .search.block .short > .field:nth-child(4) {
    min-width: 40px;
  }

  .search.block .short > .field > label .flags .flag {
    margin-right: 0;
  }

  .search.block .short > .field > label .flags .element {
    font-size: 0;
  }
}

@media (max-width: 530px) {
  .search.block .short > .field {
    padding: 0 10px;
  }

  .search.block .short > .field > label .icon-wrap {
    display: none;
  }
}

@media (max-width: 1120px) {
  .search.block .short .hide-mobile {
    display: none;
  }
}

.tiles.block {
  background: #fff;
  padding-bottom: 100px;
}

.tiles.block .tile-row {
  margin-bottom: 20px;
  display: flex;
}

.tiles.block .tile-row.x-2 .item .body {
  height: 300px;
}

.tiles.block .tile-row.x-3 .item {
  flex-basis: 35%;
}

.tiles.block .tile-row.x-3 .item .body {
  height: 300px;
}

.tiles.block .tile-row.x-3 .item .body .bottom {
  height: 165px;
}

.tiles.block .tile-row.x-4 .item {
  flex-basis: 25%;
}

.tiles.block .tile-row.x-4 .item .body {
  height: 220px;
}

.tiles.block .tile-row.x-4 .item .body.offer {
  margin-bottom: 63px;
}

.tiles.block .tile-row.x-4 .info {
  padding: 14px 14px 24px;
}

.tiles.block .tile-row.x-4 .info .title {
  min-height: 34px;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
}

.tiles.block .tile-row .item {
  flex-grow: 1;
  margin-right: 20px;
  position: relative;
}

.tiles.block .tile-row .item:last-child {
  margin-right: 0;
}

.tiles.block .tile-row .item .body {
  cursor: pointer;
  background: #f5f5f5 top / cover;
  border-radius: 20px;
  min-height: 50px;
  overflow: hidden;
}

.tiles.block .tile-row .item .info {
  cursor: pointer;
  padding: 10px 10px 18px;
  display: flex;
}

.tiles.block .tile-row .item .title {
  margin-top: 8px;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
}

.tiles.block .tile-row .item .count {
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
}

.tiles.block h2 {
  margin: 76px 0 30px;
  font-weight: 500;
}

.tiles.block .circle-arrow {
  width: 40px;
  height: 40px;
  margin: 16px 16px auto auto;
}

.tiles.block .circle-arrow .icon-arrow {
  transform: rotate(-135deg);
}

@media (max-width: 768px) {
  .tiles.block {
    margin-bottom: 100px;
    padding-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
  }

  .tiles.block .tile-row {
    margin-bottom: 0;
  }

  .tiles.block .tile-row .item {
    margin-bottom: 20px;
    margin-right: 0;
  }

  .tiles.block .tile-row.x-2, .tiles.block .tile-row.x-3 {
    flex-direction: column;
  }

  .tiles.block .tile-row.x-3 .body {
    height: 300px;
  }

  .tiles.block .tile-row.x-4 {
    flex-wrap: wrap;
    gap: 20px;
  }

  .tiles.block .tile-row.x-4 .item {
    flex-basis: 45%;
  }
}

.dropdown.room-details {
  -webkit-user-select: none;
  user-select: none;
  width: 360px;
  min-height: 0;
  max-height: 70vh;
  padding: 28px 36px;
  left: auto;
  right: 0;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .dropdown.room-details {
    left: 0;
    right: auto;
  }
}

.dropdown.room-details h3 {
  padding: 35px 0 20px;
  font-size: 16px;
  line-height: 19px;
}

.dropdown.room-details h4 {
  padding: 5px 0;
}

.dropdown.room-details .input {
  overflow: hidden;
}

.dropdown.room-details .row {
  margin-bottom: 15px;
  line-height: 40px;
  display: flex;
}

.dropdown.room-details .row.children {
  flex-wrap: wrap;
  width: 310px;
}

.dropdown.room-details .row.children .part {
  flex-grow: 0;
  width: 135px;
  margin-top: 10px;
  margin-right: 18px;
  display: flex;
}

.dropdown.room-details .row.children .part .field {
  width: 52px;
  min-width: 0;
  margin: 0;
}

.dropdown.room-details .row.children .part .field .input {
  height: 40px;
  padding: 0 5px;
  line-height: 38px;
}

.dropdown.room-details .row.children .part .field .input input {
  text-align: center;
}

.dropdown.room-details .row.children .part .btn {
  margin-left: 3px;
}

.dropdown.room-details .row:last-child {
  margin-bottom: 0;
}

.dropdown.room-details .row .caption {
  flex-grow: 1;
}

.dropdown.room-details .row .btn {
  text-align: center;
  opacity: .5;
  border: 1px solid #e5e5e5;
  border-radius: 25px;
  width: 40px;
  height: 40px;
  line-height: 37px;
  display: block;
}

.dropdown.room-details .row .btn.enabled {
  opacity: 1;
}

.dropdown.room-details .row .btn.enabled, .dropdown.room-details .row .btn.active {
  cursor: pointer;
}

.dropdown.room-details .row .btn.enabled:hover, .dropdown.room-details .row .btn.active:hover {
  color: #fdca00;
  border-color: #fdca00;
}

.dropdown.room-details .row .value {
  text-align: center;
  width: 53px;
  font-size: 14px;
}

.dropdown.date {
  width: 664px;
  min-height: 0;
  max-height: none;
  padding: 25px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .dropdown.date {
    width: 350px;
    margin-left: 0;
    left: 0;
    transform: none;
  }
}

.dropdown.residency {
  width: 350px;
  min-height: 0;
  max-height: none;
  padding: 20px 20px 10px;
  left: auto;
  right: 0;
  overflow: visible;
}

.dropdown.residency .field {
  margin-bottom: 15px;
  margin-right: 0;
}

.dropdown.residency .checkbox {
  margin: 5px 10px 15px;
}

.dropdown.filters {
  width: 300px;
  min-height: 0;
  max-height: none;
  padding: 0 30px 15px;
  left: 0;
  right: auto;
}

.dropdown.filters .close-button {
  top: 10px;
  right: 10px;
}

.dropdown {
  color: #231f20;
  z-index: 100;
  background: #fff;
  border-radius: 25px;
  width: 100%;
  min-width: 120px;
  max-height: 280px;
  margin-top: 10px;
  font-size: 14px;
  animation: .15s ease-in Appear;
  position: absolute;
  left: 0;
  overflow: hidden;
  box-shadow: 0 4px 15px #00000026;
}

.dropdown b {
  font-weight: 600;
}

.dropdown .scroll {
  max-height: inherit;
  padding: 8px 0;
  overflow-y: auto;
}

.dropdown .scroll::-webkit-scrollbar {
  width: 7px;
}

.dropdown .scroll::-webkit-scrollbar-track {
  background: none;
}

.dropdown .scroll::-webkit-scrollbar-thumb {
  background-color: #e5e5e5;
}

.dropdown .line {
  cursor: pointer;
  z-index: 1;
  margin-bottom: 0;
  padding: 9px 15px 9px 27px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  position: relative;
}

.dropdown .line .flag {
  margin-right: 12px;
}

.dropdown .line:hover, .dropdown .line.focused {
  background: #f3f4f6;
}

.dropdown .line em {
  color: #777;
  margin-left: 4px;
  font-style: normal;
}

.dropdown .subtitle {
  color: #777;
  height: 24px;
  margin: 8px 0 2px;
  padding: 0 15px 0 27px;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
}

.dropdown .subtitle:first-child {
  margin-top: 0;
}

#destinationInput .line {
  justify-content: space-between;
  align-items: center;
}

#destinationInput .temporary-technical-button {
  text-align: center;
  color: #777;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  min-width: 80px;
  max-width: 80px;
  height: 24px;
  margin-left: 6px;
  font-size: 12px;
  display: inline-block;
}

#destinationInput .temporary-technical-button:hover {
  color: #231f20;
  background: #fff;
}

#destinationInput .temporary-technical-button .icon {
  margin-right: 2px;
  position: relative;
  top: -1px;
}

.form .field {
  flex-grow: 1;
  min-width: 88px;
  margin-right: 18px;
  position: relative;
}

.form .field:last-child {
  margin-right: 0;
}

.form .field .label {
  text-transform: uppercase;
  color: #777;
  z-index: 1;
  border-radius: 10px;
  height: 24px;
  margin-bottom: -13px;
  margin-left: 24px;
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  transition: color .1s;
  display: block;
  position: relative;
}

.form .field .label span {
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  max-width: 85%;
  padding: 4px;
  display: inline-block;
  overflow: hidden;
}

.form .field.error > label > .label {
  color: #e25141;
}

.form .field.focus > label > .input {
  border-color: #fdca00;
}

.form .field.focus > label > .label, .form .field.focus > label > .label span, .form .field.focus > label > .label p {
  color: #fdca00;
}

.form .field.focus .icon-arrow-expand {
  filter: none;
}

.form .field.disabled .input {
  background: #f5f9fa;
}

.form .field.disabled .input input {
  color: #231f20;
}

.form .field.disabled .label span {
  cursor: default;
  background: linear-gradient(0deg, #f5f8fa00 25%, #fff 50%, #fff0 57%);
  border-radius: 28px;
}

.form .field.no-input label {
  cursor: pointer;
}

.form .field.no-input label .inner {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.form .field.no-input label .inner .placeholder {
  color: #777;
}

.form .field input, .form .field .button {
  touch-action: none;
  width: 100%;
}

.form .field.size-medium {
  max-width: 273px;
}

@media (min-width: 1024px) {
  .form .field.agency-selector .select.dropdown {
    width: 450px;
  }
}

.form .field.agency-selector .tag {
  margin: 0 0 0 8px;
  padding: 0 4px;
}

.form .field.agency-selector .tag.tag-surrogate {
  color: #1890ff;
  background: #91d5ff22;
  border-color: #1890ff;
}

.form .field.agency-selector .tag.tag-active {
  color: #52c41a;
  background: #b7eb8f22;
  border-color: #52c41a;
}

.form .field.agency-selector .agency-id {
  color: #999;
  margin: 0 0 0 10px;
}

.form .field.agency-selector .text-holder {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
  display: inline-block;
  overflow: hidden;
}

.form .field.size-half {
  width: 50%;
}

.form .field .error-holder {
  color: #e25141;
  margin: 5px 0 0 17px;
}

.form .field.select input {
  opacity: 0;
  top: 16px;
  position: absolute !important;
}

.form .field.select, .form .field.select label, .form .field.select input {
  cursor: pointer;
}

.form .field .value-object {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.form .field .value-object.placeholder {
  color: #777;
  white-space: nowrap;
  overflow: hidden;
}

.form .field .input {
  color: #231f20;
  background: #fff;
  border: 1px solid #09090b20;
  border-radius: 40px;
  padding: 0 27px;
  font-size: 14px;
  line-height: 44px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.form .field .input em {
  color: #777;
  font-style: normal;
}

.form .field .input .inner {
  flex-grow: 2;
  display: inline-block;
  position: relative;
}

.form .field .input .suggestion {
  white-space: nowrap;
  z-index: 1;
  color: #777;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
}

.form .field .input .suggestion.solid {
  color: #231f20;
}

.form .field .input .suggestion span {
  color: #fff;
}

.form .field .input input[type="text"], .form .field .input input[type="password"], .form .field .input textarea {
  z-index: 3;
  touch-action: none;
  background: none;
  border: 0;
  width: 100%;
  font-size: 14px;
  position: relative;
}

.form .field .input input[type="text"]::placeholder, .form .field .input input[type="password"]::placeholder, .form .field .input textarea::placeholder {
  color: #777;
  opacity: 1;
}

.form .field .input.textarea {
  border-radius: 16px;
  height: auto;
  min-height: 80px;
}

.form .field .input.textarea .inner {
  padding-top: 14px;
  padding-bottom: 6px;
  overflow: hidden;
}

.form .field .input.textarea .inner textarea {
  resize: none;
  height: 60px;
  min-height: 40px;
  max-height: 200px;
  line-height: 20px;
  display: block;
  overflow: hidden;
  width: 100% !important;
}

.form .field .input.textarea .placeholder {
  color: #777;
}

.form .field .input .clear {
  cursor: pointer;
  background: url("cross-small.e26df3b0.svg") no-repeat;
  width: 8px;
  height: 8px;
  margin-left: 14px;
  display: inline-block;
}

.form .field .input .icon-wrap {
  margin-right: 8px;
}

.form .field .input .icon-wrap .icon {
  background-position: 50% 0;
}

.form .field .input .after-icon-wrap {
  cursor: pointer;
  padding-left: 14px;
}

.never-submitted .possible-hide {
  display: none;
}

span.required:after {
  content: " *";
  color: red;
}

.form {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.form .row {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
}

.form .vertical-label {
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  width: auto;
  margin-top: -3px;
  padding-left: 14px;
  display: flex;
}

.form .capitalize input, .form .capitalize .suggestion span {
  text-transform: capitalize;
}

.checkbox {
  -webkit-user-select: none;
  user-select: none;
  background: url("checkbox-off.155cc97e.svg") 0 no-repeat;
  height: 20px;
  padding-left: 27px;
  line-height: 22px;
  display: inline-flex;
}

.checkbox.readonly {
  color: #777;
}

.checkbox:not(.readonly) {
  cursor: pointer;
}

.checkbox:hover:not(.readonly) {
  color: #fdca00;
}

.checkbox.on {
  background-image: url("checkbox-on.96233e70.svg");
}

.checkbox-wrapper {
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  display: flex;
}

.checkbox-wrapper .icon-check {
  display: none;
}

.new-filters .checkbox-wrapper {
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  gap: 14px;
  font-size: 14px;
  display: flex;
}

.new-filters .checkbox-wrapper span {
  line-height: 110%;
}

.new-filters .checkbox-wrapper.readonly {
  color: #777;
}

.new-filters .checkbox-wrapper:not(.readonly) {
  cursor: pointer;
}

.new-filters .checkbox-wrapper:hover:not(.readonly) {
  color: #fdca00;
}

.new-filters .icon-check {
  display: inline;
}

.new-filters .checkbox {
  background-image: none;
  border: 2px solid #09090b66;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  width: 18px;
  min-width: 18px;
  height: 18px;
  padding-top: 1px;
  padding-left: 0;
  transition: all .3s;
  display: flex;
}

.new-filters .checkbox.on {
  background-color: #fdca00;
  border-color: #fdca00;
}

.field-children {
  margin-left: 12px;
}

.field-children .age {
  text-transform: uppercase;
  color: #777;
  margin-left: 16px;
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
}

.field-children .button {
  width: 44px;
  height: 44px;
  margin-top: 6px;
  margin-right: 4px;
  padding-left: 0;
  padding-right: 0;
  font-size: 7px;
}

.field-children .button .icon {
  opacity: .7;
}

.field-children .button.main .icon {
  opacity: .95;
}

.input-range__slider {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background: #fdca00;
  border: 1px solid #fdca00;
  border-radius: 100%;
  outline: none;
  width: 20px;
  height: 18px;
  margin-top: -13px;
  margin-left: -10px;
  transition: transform .3s ease-out, box-shadow .3s ease-out;
  display: block;
  position: absolute;
  top: 13px;
}

.input-range__slider:active {
  transform: scale(1.1);
}

.input-range__slider:focus {
  box-shadow: 0 0 0 5px #3f51b533;
}

.input-range--disabled .input-range__slider {
  box-shadow: none;
  background: #ccc;
  border: 1px solid #ccc;
  transform: none;
}

.input-range__slider-container {
  transition: left .3s ease-out;
}

.input-range__label {
  color: #aaa;
  white-space: nowrap;
  font-size: .8rem;
  transform: translateZ(0);
}

.input-range__label--min, .input-range__label--max {
  position: absolute;
  bottom: -18px;
}

.input-range__label--min {
  left: 0;
}

.input-range__label--max {
  right: 0;
}

.input-range__label--value {
  position: absolute;
  top: -1.8rem;
}

.input-range__label--value .input-range__label-container {
  display: none;
}

.input-range__label-container {
  color: #09090b;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.input-range__track {
  cursor: pointer;
  background: #f4f4f4;
  border-radius: 8px;
  height: 8px;
  transition: left .3s ease-out, width .3s ease-out;
  display: block;
  position: relative;
  top: 12px;
}

.input-range--disabled .input-range__track {
  background: #eee;
}

.input-range__track--background {
  margin-top: -4px;
  position: absolute;
  top: 50%;
  left: 8px;
  right: 8px;
}

.input-range__track--active {
  background: #f9a51a;
}

.input-range {
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  height: 33px;
  margin-bottom: 15px;
  padding-left: 8px;
  position: relative;
}

.switch-control {
  cursor: pointer;
  background: #e5e5e5;
  border-radius: 50px;
  width: 51px;
  height: 31px;
  transition: background .15s;
  display: inline-block;
  position: relative;
}

.switch-control.active {
  background: #faa61d;
}

.switch-control.active:after {
  transform: translateX(20px);
}

.switch-control:after {
  content: " ";
  background: #fff;
  border-radius: 50px;
  width: 27px;
  height: 27px;
  transition: transform .3s ease-out;
  position: absolute;
  top: 2px;
  left: 2px;
  box-shadow: 0 3px 1px #0000000d;
}

.lg {
  font-size: 16px;
  line-height: 140%;
}

.lg .text {
  font-size: 20px;
  line-height: 140%;
}

.lg h1 {
  font-size: 52px;
  font-weight: 500;
  line-height: 130%;
}

@media (max-width: 1024px) {
  .lg h1 {
    font-size: 48px;
  }
}

@media (max-width: 640px) {
  .lg h1 {
    font-size: 32px;
  }
}

.lg h2 {
  font-size: 44px;
  font-weight: 500;
  line-height: 120%;
}

@media (max-width: 1024px) {
  .lg h2 {
    font-size: 36px;
  }
}

@media (max-width: 640px) {
  .lg h2 {
    font-size: 32px;
  }
}

.lg h2 span {
  color: #fdca00;
}

.lg h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 120%;
}

@media (max-width: 640px) {
  .lg h3 {
    font-size: 24px;
  }
}

.lg .hide-desktop {
  display: none;
}

@media (max-width: 640px) {
  .lg .hide-desktop {
    display: block;
  }

  .lg .hide-mobile {
    display: none;
  }
}

.lg .hide-laptop-desktop {
  display: none;
}

.lg .hide-laptop {
  display: block;
}

@media (max-width: 1024px) {
  .lg .hide-laptop-desktop {
    display: block;
  }

  .lg .hide-laptop {
    display: none;
  }
}

.icon {
  vertical-align: text-bottom;
  background: center no-repeat;
  display: inline-block;
}

.icon.icon-mastercard {
  background-image: url("./images/landing/footer/mastercard.svg");
  width: 60px;
  height: 38px;
}

.icon.icon-mastercard-id {
  background-image: url("./images/landing/footer/mastercard-id.svg");
  width: 38px;
  height: 38px;
}

.icon.icon-visa {
  background-image: url("./images/landing/footer/visa.svg");
  width: 56px;
  height: 38px;
}

.icon.icon-visa-secure {
  background-image: url("./images/landing/footer/visa-secure.svg");
  width: 38px;
  height: 38px;
}

.icon.icon-amex {
  background-image: url("./images/landing/footer/amex.svg");
  width: 38px;
  height: 38px;
}

.icon.icon-plane {
  background-image: url("./images/landing/plane.svg");
  width: 22px;
  height: 22px;
}

.icon.icon-burger-menu {
  background-image: url("./images/landing/header/burger-menu.svg");
  width: 16px;
  height: 16px;
}

.icon.icon-cross {
  background-image: url("./images/landing/header/cross.svg");
  width: 16px;
  height: 16px;
}

.icon.icon-arrow {
  background-image: url("./images/landing/arrow.svg");
  width: 16px;
  height: 16px;
}

.icon.icon-place {
  background-image: url("./images/landing/home/place.svg");
  width: 14px;
  height: 16px;
}

.icon.icon-minus {
  background-image: url("./images/landing/minus.svg");
  width: 18px;
  height: 18px;
}

.icon.icon-avatar {
  background-image: url("./images/landing/avatar.svg");
  width: 32px;
  height: 32px;
}

@font-face {
  font-family: Inter;
  src: url("Inter-Bold.262a7633.ttf") format("truetype"), url("Inter-ExtraBold.7f2eb5d9.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url("Inter-Medium.57962c8a.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Inter;
  src: url("Inter-Light.efff5316.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url("Inter-ExtraLight.92a2c744.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url("Inter-Regular.30288fd8.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Inter;
  src: url("Inter-SemiBold.f5225417.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

.lg-header {
  height: auto;
  margin-bottom: 72px;
  position: relative;
}

@media (max-width: 1024px) {
  .lg-header {
    margin-bottom: 48px;
  }
}

@media (max-width: 768px) {
  .lg-header {
    margin-bottom: 30px;
  }
}

.lg-header nav {
  z-index: 100;
  background-color: #f4f4f7;
  border-radius: 24px;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0;
  padding: 16px 32px;
  display: flex;
  position: relative;
}

@media (max-width: 768px) {
  .lg-header nav {
    padding: 12px 16px;
  }
}

.lg-header .logo {
  width: 17%;
  max-width: 205px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1024px) {
  .lg-header .logo {
    width: 150px;
  }
}

@media (max-width: 768px) {
  .lg-header .logo {
    width: 116px;
  }
}

.lg-header .links-group {
  gap: 8px;
  display: flex;
}

@media (max-width: 1024px) {
  .lg-header .links-group {
    display: none;
  }
}

.lg-header .link-wrapper {
  align-items: center;
  gap: 8px;
  display: flex;
}

.lg-header .link-wrapper span {
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.lg-header .link-wrapper:hover span {
  opacity: 1;
}

.lg-header .link-wrapper[data-active-link="true"] .dark-link {
  border-bottom: 1px solid #09090b;
}

.lg-header .buttons-group {
  gap: 20px;
  display: flex;
}

@media (max-width: 768px) {
  .lg-header .buttons-group {
    display: none;
  }
}

.lg-header .icon-cross, .lg-header .icon-burger-menu {
  cursor: pointer;
}

.lg-header .hide-element {
  display: none;
}

.lg-header .mobile-menu {
  opacity: 0;
  z-index: 30;
  background-color: #f4f4f7;
  border-radius: 24px;
  flex-direction: column;
  width: 100%;
  height: 0;
  padding: 84px 32px 16px;
  transition: all .5s;
  display: flex;
  position: absolute;
  top: -32px;
}

.lg-header .mobile-menu .mobile-buttons-group {
  gap: 16px;
  width: 100%;
  margin-top: 40px;
  display: flex;
}

@media (max-width: 640px) {
  .lg-header .mobile-menu .mobile-buttons-group {
    flex-direction: column;
  }
}

.lg-header .mobile-menu .mobile-buttons-group button {
  flex: 1;
}

.lg-header .mobile-menu .mobile-links {
  width: 100%;
}

.lg-header .mobile-menu .mobile-links .dark-link {
  text-transform: capitalize;
  font-size: 34px;
  line-height: 180%;
}

@media (max-width: 768px) {
  .lg-header .mobile-menu .mobile-links .dark-link {
    font-size: 24px;
  }
}

.lg-header .mobile-menu .mobile-links .link-wrapper span {
  display: none;
}

.lg-header .mobile-menu .mobile-links .link-wrapper[data-active-link="true"] .dark-link {
  border-bottom-color: #0000;
}

.lg-header .mobile-menu .mobile-links .link-wrapper[data-active-link="true"] span {
  opacity: 1;
  margin-right: 8px;
  display: block;
}

@media (max-width: 1024px) {
  .lg-header .mobile-menu-open {
    opacity: 1;
    height: 95dvh;
    top: 0;
  }
}

.lg-header .join-us-link {
  display: none;
}

@media (max-width: 768px) {
  .lg-header .join-us-link {
    display: block;
  }
}

.lg-header .initialized-buttons {
  align-items: center;
  gap: 16px;
  display: flex;
}

.lg-header .agent-link {
  background-color: #fff;
  border: 1px solid #09090b20;
  border-radius: 30px;
  align-items: center;
  height: 46px;
  margin-left: 14px;
  padding: 5px;
  transition: all .3s;
  display: flex;
}

.lg-header .agent-link:hover {
  border-color: #fdca00;
}

@media (max-width: 768px) {
  .lg-header .agent-link {
    height: auto;
    padding: 2px;
  }
}

.lg-header .agent-link .icon-burger-menu {
  background-size: 15px;
}

@media (max-width: 768px) {
  .lg-header .agent-link .icon-burger-menu {
    background-size: 10px;
  }

  .lg-header .agent-link .icon-avatar, .lg-header .agent-linksvg, .lg-header .agent-linkrect {
    width: 22px;
    height: 22px;
    margin: 0;
  }
}

.lg-footer {
  color: #09090b;
  background-color: #f4f4f7;
  margin: 0;
  padding: 40px 0;
}

@media (max-width: 640px) {
  .lg-footer {
    padding-top: 0;
  }
}

.lg-footer section {
  flex-direction: column;
  margin: 0 auto;
  display: flex;
}

.lg-footer .links-wrapper {
  border-bottom: 1px solid #09090b;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 40px;
  display: flex;
}

@media (max-width: 640px) {
  .lg-footer .links-wrapper {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 48px;
  }
}

.lg-footer .logo {
  width: 112px;
  margin: 0 auto 40px;
}

.lg-footer .links-group {
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  display: flex;
}

@media (max-width: 640px) {
  .lg-footer .links-group {
    flex-wrap: wrap;
    gap: 24px;
    width: 100%;
  }
}

.lg-footer .links-group:last-child {
  min-width: 40%;
}

@media (max-width: 1024px) {
  .lg-footer .links-group:last-child {
    justify-content: space-around;
    width: 100%;
  }
}

@media (max-width: 640px) {
  .lg-footer .links-group:last-child {
    width: auto;
  }
}

.lg-footer .image {
  width: 100%;
  max-width: 1200px;
  max-height: 250px;
  margin: 30px auto;
}

.lg-footer .image.pointer {
  cursor: pointer;
}

.lg-footer .image-logo {
  max-width: 500px;
  position: absolute;
  top: 70px;
  right: 40px;
}

@media (max-width: 1024px) {
  .lg-footer .image-logo {
    max-width: 400px;
    top: 50px;
    right: 20px;
  }
}

@media (max-width: 640px) {
  .lg-footer .image-logo {
    max-width: 300px;
  }
}

.lg-footer .info-wrapper {
  padding-top: 40px;
}

.lg-footer .info {
  grid-column-gap: 16px;
  grid-template-columns: 30% repeat(3, 1fr);
  margin-bottom: 24px;
  display: grid;
}

@media (max-width: 1024px) {
  .lg-footer .info {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .lg-footer .info {
    grid-template-columns: repeat(1, 1fr);
  }
}

.lg-footer .info p {
  margin-bottom: 12px;
}

.lg-footer .info span {
  color: #757582;
}

.lg-footer .contact-link {
  color: #757582 !important;
}

.lg-footer .icons-group {
  flex-wrap: wrap;
  justify-content: end;
  gap: 16px;
  display: flex;
}

@media (max-width: 1024px) {
  .lg-footer .icons-group {
    justify-content: start;
  }
}

@media (max-width: 768px) {
  .lg-footer .icons-group {
    margin: 16px 0;
  }
}

.new-swiper-lg {
  -webkit-user-select: none;
  user-select: none;
  position: relative;
}

.new-swiper-lg .slider-list {
  width: 100%;
  overflow-x: auto;
}

.new-swiper-lg .slider-list::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.new-swiper-lg .slider-list::-webkit-scrollbar-thumb {
  background: none;
}

.new-swiper-lg .slider-list::-webkit-scrollbar-track {
  background: none;
}

.new-swiper-lg .slider-track {
  touch-action: pan-y;
  gap: 16px;
  width: max-content;
  display: flex;
}

.new-swiper-lg .circle-arrow {
  cursor: pointer;
  z-index: 1;
  opacity: 1;
  width: 40px;
  height: 40px;
  transition: all .3s;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.new-swiper-lg .prev-arrow {
  left: 4px;
}

.new-swiper-lg .prev-arrow .icon-arrow {
  transform: rotate(90deg);
}

.new-swiper-lg .next-arrow {
  right: 4px;
}

.new-swiper-lg .next-arrow .icon-arrow {
  transform: rotate(-90deg);
}

.new-swiper-lg .hide-arrow {
  opacity: 0;
}

.text-block {
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

@media (max-width: 1024px) {
  .text-block {
    grid-template-columns: 1fr;
  }
}

.text-block .text-wrapper {
  flex-direction: column;
  display: flex;
}

.text-block p {
  margin-bottom: 46px;
}

@media (max-width: 768px) {
  .text-block p {
    margin-bottom: 24px;
  }
}

.text-block .icon-plane {
  margin: 0 20px;
}

.blog-lg h1 {
  text-align: center;
}

.blog-lg .content {
  grid-column-gap: 20px;
  grid-row-gap: 60px;
  grid-template-columns: repeat(2, 1fr);
  padding: 60px 0;
  display: grid;
}

@media (max-width: 768px) {
  .blog-lg .content {
    grid-template-columns: repeat(1, 1fr);
    padding: 40px 0;
  }
}

.blog-lg .image {
  object-fit: cover;
  border-radius: 20px;
  width: 100%;
  height: 400px;
}

.blog-lg .title {
  margin: 32px 0 10px;
}

.article-page {
  max-width: 800px;
  margin: 0 auto 100px;
}

.article-page .title {
  text-align: left;
  margin: 32px 0 40px;
}

@media (max-width: 768px) {
  .article-page .title {
    margin-bottom: 24px;
  }
}

.article-page .image {
  border-radius: 24px;
  width: 100%;
  margin: 20px 0 40px;
}

.article-page .text p {
  margin-bottom: 20px;
}

.home-main-block {
  background-image: url("/images/landing/home/background.svg");
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
}

@media (max-width: 1024px) {
  .home-main-block {
    background: none;
  }
}

.home-main-block .heading {
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
}

.home-main-block .title {
  text-align: center;
  margin: 36px 0 32px;
}

@media (max-width: 768px) {
  .home-main-block .title {
    margin-bottom: 20px;
  }
}

.home-main-block .text {
  text-align: center;
  margin-bottom: 38px;
}

@media (max-width: 768px) {
  .home-main-block .text {
    margin-bottom: 24px;
  }
}

.home-main-block .request-button {
  margin: 0 auto;
}

@media (max-width: 768px) {
  .home-main-block .request-button, .home-main-block .request-button button {
    width: 100%;
  }
}

.home-main-block .content {
  grid-gap: 32px;
  grid-template-columns: .8fr 1fr 1.3fr .8fr 1.1fr;
  align-items: end;
  width: 100%;
  height: 460px;
  margin-top: -68px;
  display: grid;
}

@media (max-width: 1200px) {
  .home-main-block .content {
    grid-gap: 16px;
    grid-template-columns: .8fr 1fr 1fr .8fr 1.1fr;
  }
}

@media (max-width: 1024px) {
  .home-main-block .content {
    grid-row-gap: 16px;
    grid-template-columns: .9fr .9fr 1.2fr 1fr;
    height: auto;
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .home-main-block .content {
    margin-top: 32px;
  }
}

@media (max-width: 640px) {
  .home-main-block .content {
    grid-template-rows: auto auto auto;
    grid-template-columns: repeat(3, 1fr);
  }
}

.home-main-block .content img {
  object-fit: cover;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  max-height: 460px;
}

.home-main-block .content .inside-info {
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.home-main-block .content .dark-link {
  border-bottom: 1px solid #09090b;
  width: -moz-fit-content;
  width: fit-content;
}

.home-main-block .content .second-block, .home-main-block .content .fourth-block {
  height: 80%;
}

.home-main-block .content .second-block {
  background-color: #f4f4f7;
  border-radius: 20px;
  align-items: center;
  display: flex;
}

@media (max-width: 1024px) {
  .home-main-block .content .second-block {
    height: 70%;
  }
}

@media (max-width: 640px) {
  .home-main-block .content .second-block {
    height: 100%;
  }
}

.home-main-block .content .second-block img {
  height: auto;
  max-height: 100%;
}

.home-main-block .content .third-block {
  height: 220px;
}

@media (max-width: 1024px) {
  .home-main-block .content .third-block {
    height: 70%;
  }
}

@media (max-width: 640px) {
  .home-main-block .content .third-block {
    grid-area: 3 / 1 / span 1 / span 3;
    width: 100%;
    height: 150px;
  }

  .home-main-block .content .third-block img {
    object-position: 0 70%;
  }
}

.home-main-block .content .third-block .destination-plate {
  background-color: #fff;
  border-radius: 14px;
  width: -moz-fit-content;
  width: fit-content;
  margin: 20px;
  padding: 10px 24px;
}

.home-main-block .content .third-block .destination-label {
  opacity: .5;
  margin-bottom: 6px;
  font-size: 11px;
  line-height: 16px;
}

.home-main-block .content .third-block .destination-place {
  align-items: center;
  display: flex;
}

.home-main-block .content .third-block .place-name {
  margin-left: 6px;
  font-size: 15px;
  font-weight: 500;
  line-height: 0;
}

.home-main-block .content .third-block .arrow-wrapper {
  background-color: #fff;
  border-radius: 30px 30px 0 0;
  width: 48px;
  height: 50px;
  margin-left: auto;
  margin-right: 20px;
}

.home-main-block .content .third-block, .home-main-block .content .fourth-block {
  position: relative;
}

@media (max-width: 1024px) {
  .home-main-block .content .fourth-block {
    height: 100%;
  }
}

.home-main-block .content .fourth-block .inside-info .circle-arrow {
  margin: 16px 16px 0 auto;
}

.home-main-block .content .fourth-block .inside-info .icon-arrow {
  transform: rotate(-135deg);
}

.home-main-block .content .fourth-block .heading {
  background-color: #fff;
  border-radius: 14px;
  margin: 16px;
  padding: 18px 14px;
  font-size: 20px;
  font-weight: 500;
}

@media (max-width: 1200px) {
  .home-main-block .content .fourth-block .heading {
    margin: 8px;
    padding: 10px;
    font-size: 16px;
  }
}

.home-main-block .content .fifth-block {
  text-align: center;
  background-color: #f4f4f7;
  border-radius: 20px;
  flex-direction: column;
  min-height: 100%;
  display: flex;
}

@media (max-width: 1024px) {
  .home-main-block .content .fifth-block {
    flex-direction: row;
    grid-column: 1 / span 4;
    height: auto;
  }
}

@media (max-width: 640px) {
  .home-main-block .content .fifth-block {
    background-color: #fff;
    gap: 16px;
    margin-right: 16px;
    display: flex;
  }
}

.home-main-block .content .fifth-block h3 {
  line-height: 150%;
}

@media (max-width: 1200px) {
  .home-main-block .content .fifth-block h3 {
    font-size: 22px;
  }
}

.home-main-block .content .fifth-block .icon-arrow {
  transform: rotate(225deg);
}

.home-main-block .content .fifth-block .circle-arrow {
  margin: 24px 20px 8px auto;
}

@media (max-width: 1024px) {
  .home-main-block .content .fifth-block .circle-arrow {
    margin: 0 0 0 auto;
  }
}

.home-main-block .content .fifth-block .info {
  border-radius: 20px;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 8px 20px;
  display: flex;
}

@media (max-width: 1024px) {
  .home-main-block .content .fifth-block .info {
    padding: 20px;
  }
}

@media (max-width: 640px) {
  .home-main-block .content .fifth-block .info {
    padding: 8px;
  }
}

.home-main-block .content .fifth-block .info:first-child {
  background-color: #f4f4f7;
  justify-content: center;
}

.home-main-block .content .fifth-block .info:first-child p {
  opacity: .7;
  font-size: 14px;
}

.home-main-block .content .fifth-block .info:last-child {
  background-color: #f3ede6;
  border-radius: 20px;
  font-weight: 500;
}

@media (max-width: 640px) {
  .home-main-block .content .fifth-block .info:last-child {
    padding-bottom: 30px;
  }
}

.home-main-block .content .fifth-block .info:last-child p {
  margin-bottom: 22px;
  font-size: 18px;
  font-weight: 500;
}

@media (max-width: 640px) {
  .home-main-block .content .fifth-block .info:last-child p {
    margin-bottom: 8px;
  }
}

.home-technology-block .text-block {
  margin-bottom: 76px;
}

@media (max-width: 768px) {
  .home-technology-block .link-button, .home-technology-block .link-button button {
    width: 100%;
  }

  .home-technology-block .swiper-cards .slider-track {
    flex-direction: column;
    width: -moz-fit-content;
    width: fit-content;
  }

  .home-technology-block .swiper-cards .circle-arrow {
    display: none;
  }
}

@media (max-width: 768px) {
  .home-technology-block .swiper-cards .card:first-child {
    flex-direction: column;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .home-technology-block .swiper-cards .card:nth-child(2) {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .home-technology-block .swiper-cards .card:last-child {
    max-width: 100%;
  }

  .home-technology-block .swiper-cards .card {
    height: -moz-fit-content;
    height: fit-content;
    padding: 32px 16px;
  }
}

@media (max-width: 768px) {
  .home-technology-block .swiper-cards .text {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .home-technology-block .swiper-cards .phone-img-wrapper {
    width: 100%;
    height: 470px;
    margin-top: 20px;
    margin-bottom: -40px;
    display: flex;
  }
}

@media (max-width: 768px) {
  .home-technology-block .swiper-cards .phone-img {
    width: 45%;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: 0 auto;
  }
}

@media (max-width: 640px) {
  .home-technology-block .swiper-cards .phone-img {
    width: 60%;
  }
}

@property --num {
  syntax: "<integer>";
  inherits: false;
  initial-value: 0;
}

.our-story-block {
  grid-template-columns: 2fr 3fr;
  align-items: stretch;
  gap: 52px;
  margin-top: 114px;
  display: grid;
}

@media (max-width: 1200px) {
  .our-story-block {
    gap: 16px;
  }
}

@media (max-width: 768px) {
  .our-story-block {
    grid-template-columns: 1fr;
    margin-top: 56px;
  }
}

.our-story-block .icon-plane {
  margin: 0 20px;
}

.our-story-block .title {
  text-align: left;
  margin-bottom: 32px;
}

@media (max-width: 1024px) {
  .our-story-block .title {
    margin-bottom: 16px;
  }
}

.our-story-block .text {
  margin-bottom: 38px;
}

@media (max-width: 1024px) {
  .our-story-block .text {
    margin-bottom: 16px;
  }
}

@media (max-width: 768px) {
  .our-story-block .learn-more-button, .our-story-block .learn-more-button button {
    width: 100%;
  }
}

.our-story-block .info-plate {
  background-color: #f4f4f7;
  border-radius: 20px;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 28px;
  margin-left: auto;
  padding: 22px 20px;
  display: flex;
}

@media (max-width: 1024px) {
  .our-story-block .info-plate {
    display: none;
  }
}

.our-story-block .info-plate p {
  opacity: .7;
  font-size: 14px;
}

.our-story-block .info-plate .arrow-wrapper {
  background-color: #fff;
  border-radius: 30px;
  width: 40px;
  height: 60px;
  margin-left: 20px;
}

.our-story-block .info-plate .counter {
  counter-reset: num var(--num);
  transition: --num 3s;
}

.our-story-block .info-plate .counter:after {
  content: counter(num);
}

.our-story-block .images-content {
  justify-content: flex-end;
  gap: 52px;
  height: 100%;
  display: flex;
}

@media (max-width: 1200px) {
  .our-story-block .images-content {
    grid-gap: 16px;
  }
}

@media (max-width: 640px) {
  .our-story-block .images-content {
    flex-direction: column;
  }
}

.our-story-block .images-content img {
  object-fit: cover;
  border-radius: 20px;
  width: 100%;
  height: auto;
  max-height: 100%;
}

.our-story-block .images-content .main-image {
  width: 55%;
  min-width: 320px;
  height: 100%;
}

.our-story-block .images-content .main-image img {
  object-position: 35% 0;
  height: 100%;
  min-height: 100%;
}

@media (max-width: 640px) {
  .our-story-block .images-content .main-image img {
    object-position: 0;
  }
}

@media (max-width: 1024px) {
  .our-story-block .images-content .main-image {
    min-width: 280px;
  }
}

@media (max-width: 768px) {
  .our-story-block .images-content .main-image {
    width: auto;
  }
}

@media (max-width: 640px) {
  .our-story-block .images-content .main-image {
    max-height: 280px;
  }
}

.our-story-block .images-content .inside-info {
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.our-story-block .images-content .product-block-wrapper {
  flex-direction: column;
  gap: 18px;
  display: flex;
}

@media (max-width: 640px) {
  .our-story-block .images-content .product-block-wrapper {
    flex-direction: row;
    height: 160px;
  }
}

.our-story-block .images-content .product-block-wrapper .inside-info .circle-arrow {
  margin: 16px 16px 0 auto;
}

.our-story-block .images-content .product-block-wrapper .inside-info .icon-arrow {
  transform: rotate(-135deg);
}

.our-story-block .images-content .product-block {
  height: 60%;
  position: relative;
}

@media (max-width: 640px) {
  .our-story-block .images-content .product-block {
    flex: 1;
    height: auto;
  }
}

.our-story-block .images-content .product-block img {
  height: 100%;
}

.our-story-block .images-content .product-block .heading {
  background-color: #fff;
  border-radius: 14px;
  margin: 16px;
  padding: 18px 14px;
  font-size: 20px;
  font-weight: 500;
}

@media (max-width: 1200px) {
  .our-story-block .images-content .product-block .heading {
    margin: 8px;
    padding: 10px;
    font-size: 16px;
  }
}

.our-story-block .images-content .join-block {
  background-color: #f3ede6;
  background-image: url("/images/landing/about-us/pattern1.svg");
  background-position: 100% 0;
  background-repeat: no-repeat;
  border-radius: 20px;
  flex: 1;
  height: max-content;
  position: relative;
}

@media (max-width: 640px) {
  .our-story-block .images-content .join-block {
    height: 100%;
  }
}

.our-story-block .images-content .join-block h3 {
  margin: 0 32px 14px;
}

@media (max-width: 1024px) {
  .our-story-block .images-content .join-block h3 {
    margin: 0 8px 10px 10px;
    font-size: 24px;
  }
}

.home-technology-block .info-wrapper {
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 76px;
  display: grid;
}

@media (max-width: 1024px) {
  .home-technology-block .info-wrapper {
    grid-template-columns: 1fr;
  }
}

.home-technology-block p {
  margin-bottom: 46px;
}

@media (max-width: 768px) {
  .home-technology-block p {
    margin-bottom: 24px;
  }
}

.home-technology-block .icon-plane {
  margin: 0 20px;
}

@media (max-width: 768px) {
  .home-technology-block .link-button, .home-technology-block .link-button button {
    width: 100%;
  }

  .home-technology-block .swiper-cards .slider-track {
    flex-direction: column;
    width: -moz-fit-content;
    width: fit-content;
  }

  .home-technology-block .swiper-cards .circle-arrow {
    display: none;
  }
}

.home-technology-block .swiper-cards .card {
  border-radius: 24px;
  height: 440px;
  padding: 50px 40px;
  overflow: hidden;
}

.home-technology-block .swiper-cards .card:first-child {
  background-color: #f4f4f7;
  gap: 16px;
  width: 670px;
  display: flex;
}

@media (max-width: 768px) {
  .home-technology-block .swiper-cards .card:first-child {
    flex-direction: column;
    width: 100%;
  }
}

.home-technology-block .swiper-cards .card:nth-child(2) {
  background-color: #f3ede6;
  background-image: url("/images/landing/home/pattern1.svg");
  background-position: 105% 110%;
  background-repeat: no-repeat;
  max-width: 440px;
}

@media (max-width: 768px) {
  .home-technology-block .swiper-cards .card:nth-child(2) {
    max-width: 100%;
  }
}

.home-technology-block .swiper-cards .card:last-child {
  background-color: #f3ede6;
  background-image: url("/images/landing/home/pattern2.svg");
  background-position: 105% 115%;
  background-repeat: no-repeat;
  max-width: 440px;
}

@media (max-width: 768px) {
  .home-technology-block .swiper-cards .card:last-child {
    max-width: 100%;
  }

  .home-technology-block .swiper-cards .card {
    height: -moz-fit-content;
    height: fit-content;
    padding: 32px 16px;
  }
}

.home-technology-block .swiper-cards .text {
  margin: 28px 0 48px;
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 768px) {
  .home-technology-block .swiper-cards .text {
    margin-bottom: 20px;
  }
}

.home-technology-block .swiper-cards .dark-link {
  border-bottom: none;
  position: relative;
}

.home-technology-block .swiper-cards .dark-link:before {
  content: "";
  background-color: currentColor;
  width: 0;
  height: 1px;
  transition: all .2s .2s;
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.home-technology-block .swiper-cards .dark-link:after {
  content: "";
  background-color: currentColor;
  width: 100%;
  height: 1px;
  transition: all .2s;
  display: block;
  position: absolute;
  bottom: -2px;
  right: 0;
}

.home-technology-block .swiper-cards .dark-link:hover:before {
  width: 100%;
}

.home-technology-block .swiper-cards .dark-link:hover:after {
  width: 0;
}

.home-technology-block .swiper-cards .phone-img-wrapper {
  width: -moz-fit-content;
  width: fit-content;
  height: 200px;
}

@media (max-width: 768px) {
  .home-technology-block .swiper-cards .phone-img-wrapper {
    width: 100%;
    height: 470px;
    margin-top: 20px;
    margin-bottom: -40px;
    display: flex;
  }
}

.home-technology-block .swiper-cards .phone-img {
  object-fit: cover;
  object-position: top;
  max-width: 244px;
}

@media (max-width: 768px) {
  .home-technology-block .swiper-cards .phone-img {
    width: 45%;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: 0 auto;
  }
}

@media (max-width: 640px) {
  .home-technology-block .swiper-cards .phone-img {
    width: 60%;
  }
}

.join-block img {
  object-fit: cover;
  border-radius: 20px;
  flex: 1;
  width: 100%;
}

.join-block .text-block {
  grid-gap: 40px;
  justify-items: end;
}

.join-block .text-wrapper {
  max-width: 600px;
}

@media (max-width: 1024px) {
  .join-block .text-wrapper {
    max-width: none;
  }
}

.team-block .text-block {
  margin-bottom: 76px;
}

.team-block .team-wrapper {
  grid-template-rows: auto;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px 16px;
  display: grid;
}

@media (max-width: 1200px) {
  .team-block .team-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1024px) {
  .team-block .team-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .team-block .team-wrapper {
    grid-template-columns: 1fr;
  }
}

.team-block .description {
  background-color: #f3ede6;
  border-radius: 24px;
  flex: 1;
  height: 350px;
  margin-bottom: 28px;
  padding: 30px 20px;
  position: relative;
}

.team-block .description .photo {
  background-color: #f3ede6;
}

.team-block .description .icon-arrow {
  transform: rotate(-135deg);
}

.team-block .description .circle-arrow {
  width: 48px;
  height: 48px;
  margin-left: auto;
}

.team-block .gray-description {
  background-color: #f4f4f7;
}

.team-block .gray-description .photo {
  background: #f4f4f7;
}

.team-block .photo {
  z-index: 0;
  aspect-ratio: 1;
  object-fit: cover;
  opacity: 1;
  border-radius: 24px;
  width: 100%;
  height: 100%;
  transition: opacity .5s;
  position: absolute;
  top: 0;
  left: 0;
}

.team-block .card {
  flex-direction: column;
  max-width: 340px;
  min-height: 450px;
  display: flex;
}

.team-block .card:hover .photo {
  opacity: 0;
}

@media (max-width: 1024px) {
  .team-block .card {
    width: 100%;
    max-width: 100%;
  }

  .team-block .card:hover .photo {
    opacity: 1;
  }
}

.team-block .icon-wrapper {
  cursor: pointer;
  z-index: 10;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin-bottom: 6px;
  margin-left: auto;
  transition: opacity .5s;
  display: none;
  position: relative;
}

@media (max-width: 1024px) {
  .team-block .icon-wrapper {
    display: flex;
  }
}

.team-block .icon-wrapper .icon-plus {
  transition: all .3s;
  position: absolute;
  transform: rotate(90deg);
}

@media (max-width: 1024px) {
  .team-block .opened-card-description .icon-plus {
    transform: none;
  }

  .team-block .opened-card-description .photo {
    opacity: 0 !important;
  }
}

.team-block .text-wrapper {
  height: 94px;
}

.team-block .name {
  margin-bottom: 22px;
  font-size: 24px;
  font-weight: 500;
}

.team-block .position {
  font-size: 20px;
}

.team-block .link-card {
  background-image: url("/images/landing/about-us/work-with-us.png");
  background-size: cover;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
  display: flex;
}

.team-block .link-card h2 {
  margin-bottom: 8px;
  font-size: 40px;
}

@media (max-width: 1024px) {
  .team-block .link-card h2 {
    font-size: 32px;
  }
}

.team-block .link-card .arrow-wrapper {
  margin: 15px 15px 0 0;
}

.team-block .link-card .link-card-text-holder {
  background: #ffffffb3;
  border-radius: 24px;
  padding: 5px 20px 15px;
}

.team-block .link-card .link-card-text-holder, .team-block .link-card .link-card-text-holder p {
  color: #000;
}

.open-positions-block .block-heading {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
  display: flex;
}

.open-positions-block .block-heading .arrows-wrapper {
  gap: 16px;
  display: flex;
}

.open-positions-block .block-heading .circle-arrow {
  background-color: #f4f4f7;
  width: 48px;
  height: 48px;
  position: relative;
  transform: none;
}

.open-positions-block .block-heading .circle-arrow[data-disabled="true"] {
  pointer-events: none;
}

.open-positions-block .block-heading .circle-arrow[data-disabled="true"] .icon-arrow {
  opacity: .5;
}

.open-positions-block .swiper-cards .card {
  background-color: #f4f4f7;
  border-radius: 24px;
  flex-direction: column;
  justify-content: space-between;
  width: 380px;
  height: 290px;
  padding: 50px 40px;
  display: flex;
  position: relative;
}

.open-positions-block .swiper-cards .card .icon-arrow {
  transform: rotate(-135deg);
}

.open-positions-block .swiper-cards .card .circle-arrow {
  width: 48px;
  height: 48px;
  margin-left: auto;
  position: static;
  transform: none;
}

.open-positions-block .swiper-cards .card:nth-child(2) {
  background-color: #f3ede6;
  background-image: url("/images/landing/about-us/pattern3.svg");
  background-position: 10% 160%;
  background-repeat: no-repeat;
  width: 420px;
}

@media (max-width: 768px) {
  .open-positions-block .swiper-cards .card {
    height: -moz-fit-content;
    height: fit-content;
    padding: 32px 16px;
  }
}

.open-positions-block .swiper-cards .text {
  margin: 28px 0 16px;
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 768px) {
  .open-positions-block .swiper-cards .text {
    margin-bottom: 20px;
  }
}

.open-positions-block .swiper-cards .discover-card {
  background: url("/images/landing/about-us/sea.png") 0 0 / cover no-repeat;
  border-radius: 24px;
  flex-direction: column;
  justify-content: space-between;
  width: 214px;
  padding: 18px;
  display: flex;
  position: relative;
}

@media (max-width: 768px) {
  .open-positions-block .swiper-cards .discover-card {
    width: 380px;
  }
}

.open-positions-block .swiper-cards .discover-card .icon-arrow {
  transform: rotate(-135deg);
}

.open-positions-block .swiper-cards .discover-card .circle-arrow {
  margin-left: auto;
  position: static;
  transform: none;
}

.open-positions-block .swiper-cards .discover-card .heading {
  background-color: #fff;
  border-radius: 14px;
  padding: 18px 14px;
  font-size: 20px;
  font-weight: 500;
}

@media (max-width: 1200px) {
  .open-positions-block .swiper-cards .discover-card .heading {
    margin: 8px;
    padding: 10px;
    font-size: 16px;
  }
}

.partnership-block {
  grid-template-columns: .9fr 1.1fr;
  align-items: stretch;
  gap: 100px;
  margin-top: 114px;
  display: grid;
}

@media (max-width: 1200px) {
  .partnership-block {
    gap: 16px;
  }
}

@media (max-width: 1024px) {
  .partnership-block {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .partnership-block {
    grid-template-columns: 1fr;
    margin-top: 56px;
  }
}

.partnership-block .text-wrapper {
  flex-direction: column;
  display: flex;
}

.partnership-block .icon-plane {
  margin: 0 20px;
}

.partnership-block .title {
  text-align: left;
  margin-bottom: 32px;
}

.partnership-block .text {
  margin-bottom: 38px;
}

.partnership-block .links-group {
  align-items: baseline;
  gap: 24px;
  display: flex;
}

@media (max-width: 1024px) {
  .partnership-block .links-group {
    margin-bottom: 32px;
  }
}

@media (max-width: 768px) {
  .partnership-block .links-group .dark-link {
    display: none;
  }
}

.partnership-block .learn-more-button {
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .partnership-block .learn-more-button, .partnership-block .learn-more-button button {
    width: 100%;
  }
}

.partnership-block .platform-link {
  margin-top: auto;
  margin-bottom: 72px;
}

@media (max-width: 1024px) {
  .partnership-block .platform-link {
    width: 90%;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .partnership-block .platform-link {
    width: 100%;
  }
}

.partnership-block .info-plate {
  background-color: #f4f4f7;
  border-radius: 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 14px 20px;
  display: flex;
}

.partnership-block .info-plate p {
  font-size: 20px;
  font-weight: 500;
}

.partnership-block .info-plate .plate-image-wrapper {
  align-items: center;
  gap: 24px;
  display: flex;
}

.partnership-block .info-plate img {
  border-radius: 20px;
  max-width: 140px;
  max-height: 90px;
}

.partnership-block .info-plate .arrow-wrapper {
  background-color: #fff;
  border-radius: 30px;
  width: 40px;
  height: 60px;
  margin-left: 20px;
}

.partnership-block .info-plate .circle-arrow {
  margin: auto;
  animation: 3s linear infinite moveDownAndUp;
}

.partnership-block .application-image {
  object-fit: contain;
  max-width: 100%;
}

@media (max-width: 1024px) {
  .partnership-block .application-image {
    width: 85%;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .partnership-block .application-image {
    width: 100%;
  }
}

.platform-block .block-heading {
  text-align: center;
}

.platform-block .block-heading h2 {
  margin-bottom: 28px;
}

.platform-block .block-heading p {
  margin-bottom: 60px;
}

@media (max-width: 768px) {
  .platform-block .block-heading p {
    margin-bottom: 32px;
  }

  .platform-block .swiper-cards .slider-track {
    flex-direction: column;
    width: 100%;
  }

  .platform-block .swiper-cards .circle-arrow {
    display: none;
  }
}

.platform-block .swiper-cards .card-heading {
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
}

@media (max-width: 768px) {
  .platform-block .swiper-cards .card-heading {
    font-size: 20px;
  }
}

.platform-block .swiper-cards .card {
  background-color: #f4f4f7;
  border-radius: 24px;
  width: 380px;
  height: 320px;
  padding: 50px 40px;
}

.platform-block .swiper-cards .card:nth-child(2) {
  background-color: #f3ede6;
  background-image: url("/images/landing/about-us/pattern3.svg");
  background-position: 10% 160%;
  background-repeat: no-repeat;
  width: 500px;
}

@media (max-width: 768px) {
  .platform-block .swiper-cards .card:nth-child(2) {
    width: 100%;
  }

  .platform-block .swiper-cards .card {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    padding: 32px;
  }
}

.platform-block .swiper-cards .text {
  margin: 28px 0 16px;
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 768px) {
  .platform-block .swiper-cards .text {
    margin-bottom: 20px;
  }
}

.platform-block .swiper-cards .discover-card {
  background: url("/images/landing/product/beach-background.png") 0 0 / cover no-repeat;
  border-radius: 24px;
  flex-direction: column;
  justify-content: space-between;
  width: 214px;
  padding: 18px;
  display: flex;
  position: relative;
}

@media (max-width: 768px) {
  .platform-block .swiper-cards .discover-card {
    width: 100%;
  }
}

.platform-block .swiper-cards .discover-card .icon-arrow {
  transform: rotate(-135deg);
}

.platform-block .swiper-cards .discover-card .circle-arrow {
  margin-left: auto;
  position: static;
  transform: none;
}

@media (max-width: 768px) {
  .platform-block .swiper-cards .discover-card .circle-arrow {
    display: flex;
  }
}

.platform-block .swiper-cards .discover-card .heading {
  background-color: #fff;
  border-radius: 14px;
  padding: 18px 14px;
  font-size: 20px;
  font-weight: 500;
}

@media (max-width: 1200px) {
  .platform-block .swiper-cards .discover-card .heading {
    width: -moz-fit-content;
    width: fit-content;
    margin: 8px;
    padding: 10px 14px;
    font-size: 16px;
  }
}

@property --revenue {
  syntax: "<integer>";
  inherits: false;
  initial-value: 0;
}

@property --bookings {
  syntax: "<integer>";
  inherits: false;
  initial-value: 0;
}

@property --partners {
  syntax: "<integer>";
  inherits: false;
  initial-value: 0;
}

.opinion-block h2 {
  text-align: center;
  margin-bottom: 60px;
}

@media (max-width: 768px) {
  .opinion-block h2 {
    margin-bottom: 32px;
  }
}

.opinion-block .swiper-cards .card-heading {
  font-size: 24px;
  font-weight: 500;
  line-height: 130%;
}

@media (max-width: 768px) {
  .opinion-block .swiper-cards .card-heading {
    font-size: 20px;
  }
}

.opinion-block .swiper-cards .card {
  background-color: #f4f4f7;
  border-radius: 24px;
  flex-direction: column;
  width: 380px;
  height: 400px;
  padding: 50px 40px;
  display: flex;
}

.opinion-block .swiper-cards .card:first-child, .opinion-block .swiper-cards .card:nth-child(4) {
  background-color: #f3ede6;
  background-image: url("/images/landing/product/pattern1.svg");
  background-position: 115% 115%;
  background-repeat: no-repeat;
}

.opinion-block .swiper-cards .card:first-child {
  width: 550px;
}

@media (max-width: 768px) {
  .opinion-block .swiper-cards .card:first-child {
    width: 430px;
  }

  .opinion-block .swiper-cards .card {
    padding: 32px;
  }
}

.opinion-block .swiper-cards .text {
  margin: 4px 0;
  font-size: 16px;
}

.opinion-block .swiper-cards h3 {
  font-weight: 600;
}

.opinion-block .swiper-cards .small-text {
  font-size: 14px;
}

.opinion-block .swiper-cards .small-text:first-child {
  margin-bottom: 24px;
}

.opinion-block .swiper-cards .small-text:last-child {
  margin-top: 24px;
}

.opinion-block .swiper-cards .discover-card {
  background: url("/images/landing/product/sea-building-background.png") 0 0 / cover no-repeat;
  border-radius: 24px;
  flex-direction: column;
  justify-content: space-between;
  width: 285px;
  padding: 18px;
  display: flex;
  position: relative;
}

.opinion-block .swiper-cards .discover-card .icon-arrow {
  transform: rotate(-135deg);
}

.opinion-block .swiper-cards .discover-card .circle-arrow {
  margin-left: auto;
  position: static;
  transform: none;
}

@media (max-width: 768px) {
  .opinion-block .swiper-cards .discover-card .circle-arrow {
    display: flex;
  }
}

.opinion-block .swiper-cards .discover-card .heading {
  background-color: #fff;
  border-radius: 14px;
  padding: 18px 14px;
  font-size: 20px;
  font-weight: 500;
}

@media (max-width: 1200px) {
  .opinion-block .swiper-cards .discover-card .heading {
    width: -moz-fit-content;
    width: fit-content;
    margin: 8px;
    padding: 10px 14px;
    font-size: 16px;
  }
}

.opinion-block .swiper-cards .counters-wrapper {
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  display: flex;
}

.opinion-block .swiper-cards .counters-wrapper h3 {
  font-size: 40px;
}

@media (max-width: 768px) {
  .opinion-block .swiper-cards .counters-wrapper h3 {
    font-size: 36px;
  }
}

.opinion-block .swiper-cards .revenue-number {
  counter-reset: revenue var(--revenue);
  transition: --revenue 5s;
}

.opinion-block .swiper-cards .revenue-number:after {
  content: counter(revenue);
}

.opinion-block .swiper-cards .bookings-number {
  counter-reset: bookings var(--bookings);
  transition: --bookings 7s;
}

.opinion-block .swiper-cards .bookings-number:after {
  content: counter(bookings);
}

.opinion-block .swiper-cards .partners-number {
  counter-reset: partners var(--partners);
  transition: --partners 5s;
}

.opinion-block .swiper-cards .partners-number:after {
  content: counter(partners);
}

.tailor-made-block {
  text-align: center;
  background-color: #f3ede6;
  background-image: url("../images/landing/product/tailor-made-background.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 24px;
  justify-content: center;
  display: flex;
  padding: 0 100px !important;
}

@media (max-width: 1024px) {
  .tailor-made-block {
    background-image: url("../images/landing/product/tailor-made-bg-mobile.png");
    padding: 0 32px !important;
  }
}

@media (max-width: 640px) {
  .tailor-made-block {
    background-size: cover;
  }
}

.tailor-made-block .content {
  max-width: 600px;
  padding: 100px 0;
}

@media (max-width: 1024px) {
  .tailor-made-block .content {
    padding: 120px 0 200px;
  }
}

.tailor-made-block .text {
  margin: 32px 0 40px;
}

.questions-block h2 {
  margin-bottom: 60px;
}

.questions-block .content {
  grid-gap: 16px;
  grid-template-columns: 2fr 1fr;
  display: grid;
}

@media (max-width: 1200px) {
  .questions-block .content {
    grid-template-columns: 1fr;
  }
}

.questions-block .content[data-auth="true"] {
  grid-template-columns: 1fr;
}

.questions-block .questions-list {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.questions-block .question-card {
  cursor: pointer;
  background-color: #f4f4f7;
  border-radius: 24px;
  padding: 34px 40px;
  transition: all .3s;
}

.questions-block .question-card .text {
  pointer-events: none;
  opacity: 0;
  height: 0;
  margin: 0;
  transition: opacity .1s, height 4s, margin .6s;
}

.questions-block .question {
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  display: flex;
}

.questions-block .question p {
  font-size: 24px;
  font-weight: 500;
  line-height: 130%;
}

.questions-block .icon-wrapper {
  cursor: pointer;
  z-index: 10;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  height: 48px;
  margin-bottom: 6px;
  margin-left: auto;
  transition: opacity .5s;
  display: flex;
  position: relative;
}

.questions-block .icon-wrapper .icon-plus {
  transition: all .3s;
  position: absolute;
  top: 15px;
  left: 15px;
  transform: rotate(90deg);
}

.questions-block .question-card[data-opened="true"] {
  background-color: #f3ede6;
}

.questions-block .question-card[data-opened="true"] .icon-plus {
  transform: none;
}

.questions-block .question-card[data-opened="true"] .text {
  pointer-events: auto;
  opacity: 1;
  height: auto;
  margin-top: 40px;
  transition: opacity 1s, height 1s, margin .6s;
}

.questions-block .image-block {
  background-image: url("../images/landing/product/questions-background.png");
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 24px;
  height: 100%;
  padding: 40px;
}

@media (max-width: 1200px) {
  .questions-block .image-block {
    padding: 20px;
  }
}

@media (max-width: 1024px) {
  .questions-block .image-block {
    grid-row: 1 / span 1;
    padding: 40px;
  }
}

.questions-block .image-block .image-block-content {
  text-align: center;
  background-color: #fff;
  border-radius: 24px;
  padding: 44px 52px;
}

@media (max-width: 1200px) {
  .questions-block .image-block .image-block-content {
    padding: 24px 16px;
  }
}

@media (max-width: 1024px) {
  .questions-block .image-block .image-block-content {
    padding: 40px;
  }
}

.questions-block .image-block .image-block-content h3 {
  margin-bottom: 32px;
}

@keyframes CardOpen {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes HiddenDataOpen {
  0% {
    opacity: 0;
    width: 0;
    height: 0;
  }

  50% {
    opacity: 0;
    width: 0;
    height: 0;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    width: auto;
    height: auto;
  }
}

@keyframes ImageAnimation {
  0% {
    transform: translate(0, 300px);
  }

  40% {
    transform: translate(0, 300px);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes LoadingLine {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.services-block {
  gap: 16px;
  display: flex;
}

.services-block .card {
  cursor: pointer;
  background-color: #f3ede6;
  background-repeat: no-repeat;
  border-radius: 40px;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  gap: 30px;
  width: 160px;
  height: 650px;
  padding: 24px 50px;
  transition: flex .5s, background-color 1s, width .5s;
  display: grid;
  position: relative;
}

@media (max-width: 1200px) {
  .services-block .card {
    gap: 16px;
    width: 120px;
    padding: 32px;
  }
}

@media (max-width: 1024px) {
  .services-block .card {
    grid-template-columns: 1fr;
    width: 80px;
    height: 850px;
  }
}

@media (max-width: 768px) {
  .services-block .card {
    height: 700px;
  }
}

@media (max-width: 640px) {
  .services-block .card {
    width: 60px;
    height: 650px;
    padding: 8px;
  }
}

.services-block .card:after {
  content: "";
  background-color: #f4f4f7;
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: -30px;
}

.services-block .card:before {
  content: "";
  z-index: 10;
  background-color: #fdca00;
  width: 0;
  height: 3px;
  transition: none;
  position: absolute;
  bottom: -30px;
}

.services-block .card:first-child {
  background-image: url("/images/landing/product/pattern2.svg");
  background-position: 30% 10%;
}

.services-block .card:nth-child(2) {
  background-image: url("/images/landing/product/pattern3.svg");
  background-position: 55% 10%;
}

.services-block .card:last-child {
  background-image: url("/images/landing/product/pattern4.svg");
  background-position: 45% 10%;
}

.services-block .card .hidden-block, .services-block .card .image-wrapper, .services-block .card .image, .services-block .card img {
  width: 0;
  height: 0;
  display: none;
}

.services-block .card .text {
  margin: 32px 0;
}

@media (max-width: 640px) {
  .services-block .card .text {
    margin: 16px 0;
  }

  .services-block .card h2 {
    font-size: 24px;
  }
}

.services-block .closed-card {
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 1024px) {
  .services-block .closed-card {
    padding: 20px;
  }
}

@media (max-width: 640px) {
  .services-block .closed-card {
    align-items: center;
    padding: 8px 8px 20px;
  }
}

.services-block .closed-card .icon-wrapper {
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
  position: relative;
}

.services-block .closed-card .icon-minus {
  height: 2px;
}

.services-block .closed-card .icon-plus {
  position: absolute;
  top: 18px;
  left: 11px;
  transform: rotate(90deg);
}

.services-block .closed-card .subtitle {
  writing-mode: vertical-rl;
  width: 100%;
  margin-top: 10px;
  font-size: 24px;
  font-weight: 500;
}

@media (max-width: 640px) {
  .services-block .closed-card .subtitle {
    margin-right: 16px;
    font-size: 18px;
  }
}

.services-block .card[data-active="true"] {
  background-color: #f4f4f7;
  background-image: none;
  flex: 1;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

@media (max-width: 640px) {
  .services-block .card[data-active="true"] {
    padding: 20px;
  }
}

.services-block .card[data-active="true"]:before {
  width: 100%;
  animation: 10s LoadingLine;
}

.services-block .card[data-active="true"] .image-wrapper {
  height: 100%;
  margin-bottom: -46px;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .services-block .card[data-active="true"] .image-wrapper {
    margin-bottom: -63px;
  }
}

@media (max-width: 640px) {
  .services-block .card[data-active="true"] .image-wrapper {
    margin-bottom: -40px;
  }
}

.services-block .card[data-active="true"] .image {
  width: 100%;
  margin-top: 76px;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .services-block .card[data-active="true"] .image {
    margin-top: 0;
    display: flex;
  }
}

.services-block .card[data-active="true"] .image img {
  object-fit: cover;
  object-position: top;
  max-width: 100%;
  margin-top: 20px;
  overflow: hidden;
  transform: translate(0, 300px);
}

@media (max-width: 1024px) {
  .services-block .card[data-active="true"] .image img {
    max-width: 50%;
    margin: auto;
  }
}

@media (max-width: 640px) {
  .services-block .card[data-active="true"] .image img {
    max-width: 70%;
  }
}

.services-block .card[data-active="true"] .image-wrapper, .services-block .card[data-active="true"] .image, .services-block .card[data-active="true"] img {
  width: auto;
  height: auto;
  animation: .7s CardOpen;
  display: block;
}

.services-block .card[data-active="true"] .hidden-block {
  width: auto;
  height: auto;
  animation: .7s HiddenDataOpen;
  display: block;
}

.services-block .card[data-active="true"] .image-wrapper {
  height: 100%;
}

.services-block .card[data-active="true"] .image-wrapper img {
  animation: 1s ImageAnimation;
  transform: translate(0);
}

.services-block .card[data-active="true"] .closed-card {
  display: none;
}

.services-block .card[data-stopped-interval="true"]:before {
  transition: none;
  animation: none;
}

/*# sourceMappingURL=index.da4f69b1.css.map */
