@mixin icon($image, $width: 18, $height: 18)
    background-image: url($image)
    width: #{$width}px
    height: #{$height}px
.icon
    display: inline-block
    background: no-repeat center center
    vertical-align: text-bottom
    &.icon-burger
        @include icon("./icons/sidebars/burger-small.svg", 14, 9)
        filter: brightness(0.22)
    &.icon-arrow-next
        @include icon("./icons/elements/arrow-next.svg", 16, 16)
    &.icon-filters
        @include icon("./icons/elements/filters.svg")
    &.icon-refresh
        @include icon("./icons/elements/refresh.svg")
    &.icon-close
        @include icon("./icons/elements/close.svg", 18, 19)
    &.icon-up, &.icon-down
        @include icon("./icons/elements/up.svg")
    &.icon-down
        transform: rotate(180deg)
    &.icon-remove
        @include icon("./icons/elements/remove.svg", 16, 16)
    &.icon-arrow-expand
        @include icon("./icons/elements/arrow-expand.svg")
        filter: grayscale(1)
    &.icon-big-expand
        @include icon("./icons/elements/big-expand.svg", 34, 34)
        background-size: cover
        &.expanded
            transform: rotate(180deg)
    &.icon-small-expand
        @include icon("./icons/elements/big-expand.svg", 20, 20)
        background-size: cover
        &.expanded
            transform: rotate(180deg)
    &.icon-white-lock
        @include icon("./icons/elements/white-lock.svg")
    &.icon-info
        @include icon("./icons/elements/info-blue.svg", 12, 12)
    &.icon-logout
        @include icon("./icons/elements/logout.svg", 24, 24)
    &.icon-copy
        @include icon("./icons/elements/copy.svg", 15, 15)
    &.icon-big-mail
        @include icon("./icons/elements/big-mail.svg", 105, 115)
        background-size: cover
    &.icon-download
        @include icon("./icons/elements/download.svg", 20, 20)
    &.icon-check
        @include icon("./icons/elements/check.svg", 11, 7)

    &.icon-search-location
        @include icon("./icons/search/location.svg", 14, 16)
    &.icon-search-calendar
        @include icon("./icons/search/calendar.svg", 14, 16)
    &.icon-search-guests
        @include icon("./icons/search/guests.svg", 14, 15)
    &.icon-search-country
        @include icon("./icons/search/country.svg", 16, 16)
    &.icon-search-white
        @include icon("./icons/search/search.svg", 14, 14)
    &.icon-search
        @include icon("./icons/search/search-input.svg")
    &.icon-summary-location
        @include icon("./icons/search/summary-location.svg", 22, 22)
    &.icon-summary-guests
        @include icon("./icons/search/summary-guests.svg", 22, 22)
    &.icon-summary-calendar
        @include icon("./icons/search/summary-calendar.svg", 22, 22)

    &.icon-success
        @include icon("./icons/notification/success.svg", 40, 40)
    &.icon-warning
        @include icon("./icons/notification/warning.svg", 40, 40)
    &.icon-information
        @include icon("./icons/notification/information.svg", 40, 40)

    &.icon-boy
        background-size: contain
        @include icon("./icons/children/boy.svg", 24, 24)
    &.icon-girl
        background-size: contain
        @include icon("./icons/children/girl.svg", 24, 24)
